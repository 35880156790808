import React, { Component } from 'react'
import './AboutSection.scss'
import aboutFirstImg from '../../images/about-first.png'

class AboutSection extends Component {
  render() {
    return (
        <div className="about_section row">
        <div className="container">
            <div className="col-5 wow slideInLeft"><img src={aboutFirstImg} alt=""/></div>
            <div className="col-7">
            <h4 className="heading_style mB0 pB10  wow fadeInUp">About our <span>Machine Learning</span><br/>
    Software firm</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
    
    </p>
                <div className="d_list_style">
                <ul>
                    <li>Lorem Ipsum is simply dummy text of the printing</li>
                    <li>Lorem Ipsum is simply dummy text of the printing</li>
                    <li>Lorem Ipsum is simply dummy text of the printing</li>
                    <li>Lorem Ipsum is simply dummy text of the printing</li>
                    <li>Lorem Ipsum is simply dummy text of the printing</li>
                </ul>
                </div>
            </div>
            </div>
        </div>
    )
  }
}

export default AboutSection
import React from 'react'
import '../styles.scss';
import closeIcon from '../../../images/adminimages/ionic-md-close.png'
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from 'react-redux';
// import { addUser } from '../../../slices/admin';
import UserlistService from '../../../services/userlistService'

export default function AddUser({handlePopup}) {
    const userService = new UserlistService();
    const dispatch = useDispatch();
    const handleUserPopup=()=>{
        handlePopup();
    }     
    const initialValues = {
        name: "",
        email: "",
        username:"",
        role:"",
      };
    
      //validate the field
    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required("First name is required"),
        lastname: Yup.string().required("Last name is required"),
        email: Yup.string().required("email is required"),
        username: Yup.string().required("username is required"),
        role: Yup.string().required("role is required"),
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters long')
            .matches(/[A-Z]/, 'Password must contain at least 1 uppercase letter')
            .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least 1 special character')
            .required('Password is required'),
        confirmpassword: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords and Confirm passowrd must match')
    });



      const handleSubmit = async (values) => {
        const firstname = values.firstname;
        const lastname = values.lastname;
        const email = values.email;
        const username = values.username;
        const role = values.role;
        const password = values.password;
        const confirmpassword = values.confirmpassword;
        const payload = {
            first_name:firstname,
            last_name:lastname,
            username:username,
            password1: password,
            password2:confirmpassword,
            role:role,
            email:email
        }
        await userService.addUser(payload);
        handlePopup();
      };

    return (
        <div className='popupoverlay'>
            <div className="popupbox user">
                <h3>User Details<img src={closeIcon} alt="closeIcon" onClick={handleUserPopup} /></h3>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="form-group">
                                <label>First Name</label>
                                <Field
                                    type="text"
                                    className="form-control"
                                    name="firstname"
                                />
                                <ErrorMessage
                                    name="firstname"
                                    component="div"
                                    className="error_message"
                                />
                            </div>
                            <div className="form-group">
                                <label>Last Name</label>
                                <Field
                                    type="text"
                                    className="form-control"
                                    name="lastname"
                                />
                                <ErrorMessage
                                    name="lastname"
                                    component="div"
                                    className="error_message"
                                />
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <Field
                                    type="email"
                                    className="form-control"
                                    name="email"
                                />

                                <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="error_message"
                                />
                            </div>
                            <div className="form-group">
                                <label>User name</label>
                                <Field
                                    type="text"
                                    className="form-control"
                                    name="username"
                                />
                                <ErrorMessage
                                    name="username"
                                    component="div"
                                    className="error_message"
                                />
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <Field
                                    type="password"
                                    className="form-control"
                                    name="password"
                                />
                                <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="error_message"
                                />
                            </div>
                            <div className="form-group">
                                <label>Confirm password</label>
                                <Field
                                    type="password"
                                    className="form-control"
                                    name="confirmpassword"
                                />
                                <ErrorMessage
                                    name="confirmpassword"
                                    component="div"
                                    className="error_message"
                                />
                            </div>
                            <div className="form-group">
                                <label>Role</label>
                                <Field as="select" id="dropdownValue" name="role">
                                    <option value="">Select an option</option>
                                    <option value="Admin">Admin</option>
                                    <option value="ML">Machine Learning</option>
                                    <option value="Sales">Sales</option>
                                </Field>
                                <ErrorMessage
                                    name="role"
                                    component="div"
                                    className="error_message"
                                />
                            </div>
                            <div className="form-group ">
                                <button
                                    className="form-control user_submit_btn"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? "Submitting..." : "Submit"}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

import React, { useEffect } from "react";
import "./Forms.scss";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ContactService from "../../services/contactService";
import { setLoading } from "../../slices/global";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { countryCodes } from "../../utils/constant";
import { useLocation } from "react-router-dom";
import { getSourceOptions } from "../../slices/admin";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

const contactService = new ContactService();

const Forms = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get('source');

  const dispatch = useDispatch();
  const initialValues = {
    fullName: "",
    email: "",
    countryCode: "+91",
    contact: "",
    message: "",
    source : source !== null ? source : "",
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
    .required("Full Name is required")
    .matches(/^[a-zA-Z\s]+$/,"Only alpha characters are allowed"),
    email: Yup.string()
    .required("Email is required")
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,"Invalid email"),
    
    contact: Yup.string()
      .required("Phone Number is required")
      .matches(/^\d{4,13}$/, "Number of digits should be valid"),
    message: Yup.string().required("Message is required"),
    source : Yup.string().required("Source is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(values)) {
      if (key === "fullName") {
        formData.append("full_name", value);
      } 
      else {
        formData.append(key, value);
      }
      console.log("Form values:", values);
      console.log("final Formdata :", formData);
    }
    try {
      dispatch(setLoading(true));
      const response = await contactService.submitFormData(formData);
      if (response) {
        toast.success(response.message);
        resetForm();
      }
    } catch (error) {
      toast.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    dispatch(getSourceOptions());
  }, [])

  const { sourcesList } = useSelector((state) => state.admin);

  return (
    // <div className="contact_form">
    //   <div className="container">
    //     <h4 className="heading_style wow fadeInUp">
    //       Let's Connect for <span>free consultation</span>
    //     </h4>
    //     <p className="wow fadeInUp">
    //       So Let us Connect and Transform your idea Into an awesome Solution. Go
    //       ahead and fill this form.
    //     </p>
    //  <div className="flexDirection">
    //   <div className="form_section">
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting,values,setFieldValue }) => (
        <Form>
          <div className="row">
            {/* <div className="form_group col-6 pR15"> */}
            <div className="form_group">
              <Field
                type="text"
                className="form_control"
                placeholder="Full Name"
                name="fullName"
              />
              <ErrorMessage
                name="fullName"
                component="div"
                className="text-danger"
              />
            </div>
          </div>
          <div className="row">
            <div className="form_group">
              <Field
                type="email"
                className="form_control"
                placeholder="Email"
                name="email"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-danger"
              />
            </div>
          </div>
          {source !== null ? (
            <div className="row">
              <div className="form_group">
                <Field
                  type="text"
                  className="form_control"
                  placeholder="Source"
                  name="source"
                />
                <ErrorMessage
                  name="source"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="form_group">
                <Field
                  as="select"
                  className="form_control"
                  name="source"
                >
                  <option value="">Select source</option>
                  {sourcesList.length > 0 &&
                    sourcesList.map((data, index) => (
                      <option key={index} value={data} label={data} />
                    ))}
                </Field>
                <ErrorMessage
                  name="source"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="form_group col-2 pR15">
              <PhoneInput
                country={"In"}
                value={values.countryCode}
                onChange={(value) => setFieldValue("countryCode", value)}
                inputProps={{
                  name: "countryCode",
                  required: true,
                }}
                inputStyle={{
                  width: "100px",
                  height: "41.5px",
                }}
              />
              {/* <Field
                        as="select"
                        className="form_control countryCode"
                        name="countryCode"
                      >
                        {countryCodes.map((item, index) => (
                          <option key={index} value={item.mobileCode}>
                            {item.mobileCode + " - " + item.name}
                          </option>
                        ))}
                      </Field> */}
            </div>
            <div className="form_group col-8">
              <Field
                type="number"
                className="form_control no_arrows"
                placeholder="Phone Number"
                name="contact"
                minLength={10}
                maxLength={10}
              />
              <ErrorMessage
                name="contact"
                component="div"
                className="text-danger"
              />
            </div>
          </div>
          <div className="row">
            <div className="form_group">
              <Field
                as="textarea"
                cols="30"
                rows="6"
                placeholder="Message"
                className="form_control hA pT10 textMessage"
                name="message"
              />
              <ErrorMessage
                name="message"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form_group t_right mB0">
              <button
                className="form_btn"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Send"}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
    //  </div>

    //  </div>
    //   </div>
    // </div>
  );
};

export default Forms;

import AppSecureStorage from "../services/storage/secureStorage";

const secureStorage = new AppSecureStorage();

const getStorageItem = (key) => {
  return secureStorage.get(key)
}
const setStorageItem = (key, value) => {
  secureStorage.set(key, value)
}

const removeStorageItem = (key) => {
  secureStorage.remove(key)
}
const clearStorage = () => {
  secureStorage.clearStorage()
}
export { getStorageItem, setStorageItem, removeStorageItem, clearStorage };
import React, { useCallback, useRef, useState, useEffect } from "react";
import Webcam from "react-webcam";
import "./WebCam.scss";

export default function WebCamImage({ getErrorMsg, item, handleWebcam }) {
  const [img, setImg] = useState(null);
  const webcamRef = useRef(null);
  const [imageFile, setImageFile] = useState(null);

  const videoConstraints = {
    width: 420,
    height: 420,
    facingMode: "user",
  };

  const capture = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImg(imageSrc);
    const imageBlob = await fetch(imageSrc).then((res) => res.blob());
    const imageFile = new File([imageBlob], "captured-image.jpg", {
      type: "image/jpeg",
    });
    setImageFile(imageFile);
    handleWebcam(imageFile, item, true)
  }, [webcamRef]);

  const handleRetake = () => {
    setImg(null);
    handleWebcam(imageFile, item, true);
  }

  useEffect(() => {
    if(imageFile) {
      handleWebcam(imageFile, item)
    }
  }, [imageFile])


  return (
    <div>
      <div className="webcamContainer Container">
        <h4>
          {item.label}
          {item.optional === "false" && " * "}
          <span className="required">{" " + getErrorMsg(item.key)}</span>
        </h4>
        <div className="upload_box row">
          <div className="file_container">
            {img === null ? (
              <Webcam
                className="picture"
                audio={false}
                mirrored={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            ) : (
              <img className="picture" src={img} alt="screenshot" />
            )}
          </div>
          {img === null ? (
            <button onClick={capture} className="webcam-btn">Capture photo</button>
          ) : (
            <div className="pictureBtn">
              <button className="webcam-btn" onClick={handleRetake}>Retake</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

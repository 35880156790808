import React, { Component } from 'react'
import ClientProject from './ClientProject'
import yearExp from '../../images/year_exp.png'
import creativeNeeds from '../../images/creative_needs.png'
import satisfiedClient from '../../images/setisfy_client.png'
import projectdeveloped from '../../images/project_developer.png'


export default class ClientProjects extends Component {

    render() {
        return (
            <div>
                <div className="our_project_section row">
                    <div className="container">
                        <ClientProject bgImg={projectdeveloped} totalnumber={500} title="Projects Developed" />
                        <ClientProject bgImg={creativeNeeds} totalnumber={200} title="Creative Nerds" />
                        <ClientProject bgImg={yearExp} totalnumber={14} title="Years of Experience" />
                        <ClientProject bgImg={satisfiedClient} totalnumber={500} title="Satisfied Clients" />
                    </div>
                </div>
            </div>
        )
    }
}

import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ApplicationsService from "../../../services/ApplicationsService";
import * as Yup from "yup";
import { Sidebar } from "../Sidebar/Sidebar";
import { setLoading } from "../../../slices/global";
import "../styles.scss";

const applicationsService = new ApplicationsService();
const CreateApplication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const imageRef = useRef();
  const videoRef = useRef();
  const [file, setFile] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showSubApp, setShowSubApp] = useState({show : false});

  const handleFileChange = (event) => {
    const targetName = event.target.name;
    const file = event.target.files[0];

    setFile((prevFile) => ({
      ...prevFile,
      [`${targetName}File`]: file,
    }));
  };

  const handleSubmit = async (values) => {
    const {
      featureKey,
      featureLabel,
      authKey,
      authType,
      featureDescription,
      featureIcon,
      featureVideo,
      is_published,
    } = values;
    if (featureKey && featureLabel) {
      const formData = new FormData();
      formData.append("featureName", featureKey);
      formData.append("featureSlug", featureLabel);
      formData.append("authKey", authKey);
      formData.append("authType", authType);
      formData.append("featureDescription", featureDescription);
      formData.append("featureImage", featureIcon);
      formData.append("featureVideo", featureVideo);
      formData.append("is_published",  is_published ? "True" : "False");
      try {
        const response = await applicationsService.createApplication(
          formData,
          dispatch
        );
        if (response && response.Success === true) {
          toast.success("Application added successfully", {
            autoClose: 2000,
          });  
          setShowSubApp((prevState) => ({
            ...prevState,
            show : true,
            featureName : response.data.featureName,
            featureId : response.data.FeatureId
          }))
        } else if (response && response.Success === false) {
          toast.error(response.data.result);
          dispatch(setLoading(false))
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  const createForm = () => {
    const initialValues = {
      featureKey: "",
      featureLabel: "",
      featureIcon: "",
      featureVideo: "",
      authKey: "",
      authType: "",
      featureDescription: "",
      is_published: false,
    };

    const validationSchema = Yup.object().shape({
      featureKey: Yup.string().required("Application Key is required")
      .matches(/^[a-z0-9-]+$/, "Application Key can only contain small letters, numbers, and hyphens"),
      featureLabel: Yup.string().required("Application Label is required"),
      featureIcon: Yup.mixed().required("Application Icon is required"),
      featureDescription: Yup.string().required("Application description is required"),
    });

    const handleAddStep = () => {
       navigate(`/controlpanel/application/${showSubApp.featureName}/${showSubApp.featureId}/step/new`);
    };

    return (
      <div className="mainbox">
        <h1>Add Application</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue,values }) => (
            <Form>
              <div className="row">
                <div className="form-group">
                  <label htmlFor="">
                    Application Key<span className="error_message"> *</span>
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    name="featureKey"
                    placeholder="Application Key"
                  />
                  <ErrorMessage
                    name="featureKey"
                    component="div"
                    className="error_message"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">
                    Application Label<span className="error_message"> *</span>
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    name="featureLabel"
                    placeholder="Application Label"
                  />
                  <ErrorMessage
                    name="featureLabel"
                    component="div"
                    className="error_message"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <label htmlFor="">
                    Application Icon<span className="error_message"> *</span>
                  </label>
                  <div className="file-group">
                  <input
                    type="file"
                    // className="form-control"
                    name="featureIcon"
                    ref={imageRef}
                    onChange={(event) => {
                      setFieldValue(
                        "featureIcon",
                        event.currentTarget.files[0]
                      );
                      setSelectedImage(
                        URL.createObjectURL(event.currentTarget.files[0])
                      );
                    }}
                    // onChange={handleFileChange}
                    // onChange={(event) => {
                    //   setFieldValue(
                    //     "featureIcon",
                    //     event.currentTarget.files[0]
                    //   );
                    // }}
                    accept="image/*"
                  />
                  </div>
                  <ErrorMessage
                    name="featureIcon"
                    component="div"
                    className="error_message"
                  />
                  <div>
                    {selectedImage && (
                      <div>
                        <img src={selectedImage} alt="Selected Image"  className="custom-image" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="">Application Video </label>
                  <div className="file-group">
                  <input
                    type="file"
                    // className="form-control"
                    name="featureVideo"
                    ref={videoRef}
                    onChange={(event) => {
                      setFieldValue(
                        "featureVideo",
                        event.currentTarget.files[0]
                      );
                      setSelectedVideo(
                        URL.createObjectURL(event.currentTarget.files[0])
                      );
                    }}
                    // onChange={handleFileChange}
                    // onChange={(event) => {
                    //   setFieldValue(
                    //     "featureVideo",
                    //     event.currentTarget.files[0]
                    //   );
                    // }}
                    accept="video/*"
                  />
                  </div>
                  <div>
                    {selectedVideo && (
                      <div >
                        <video controls   className="custom-video">
                          <source src={selectedVideo} type="video/mp4" />
                        </video>
                      </div>
                    )}
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <label htmlFor="">Auth Key</label>
                  <Field
                    type="text"
                    className="form-control"
                    name="authKey"
                    placeholder="Auth Key"
                  />
                  <ErrorMessage
                    name="authKey"
                    component="div"
                    className="error_message"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">Auth Type</label>
                  <Field
                    type="text"
                    className="form-control"
                    name="authType"
                    placeholder="Auth Type"
                  />
                  <ErrorMessage
                    name="authLabel"
                    component="div"
                    className="error_message"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <label htmlFor="">
                    Description <span className="error_message"> *</span>
                  </label>
                  <Field
                    type="textarea"
                    className="form-control"
                    name="featureDescription"
                    placeholder="Description"
                  />
                  <ErrorMessage
                    name="featureDescription"
                    component="div"
                    className="error_message"
                  />
                </div>
              </div>
              <div className="row">
              <div className="form-group">
                <label htmlFor="">Published</label>
                <Field
                  type="checkbox"
                  name="toggleValue"
                  checked={values.is_published} 
                  onChange={(e) => setFieldValue("is_published", e.target.checked)}
                  className="publish-button"
                />
              </div>

              </div>
              <div> 
                {
                  !showSubApp.show && (
                  <div className="form-group txt">
                  <button className="form_btn" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </div>
                  )
                }
              </div>
            </Form>
          )}
        </Formik>
        {
          showSubApp.show && (
            <div className="row">
                  <div className="form-group txt">
                  <h3>SubApp</h3>
                  </div>
                  <div className="row tableBox N">
                    <table>
                      <thead>
                        <tr>
                          <th width="13%">S.N.</th>
                          <th width="47%">Name</th>
                          <th width="11%">Action</th>
                          <th width="29%">&nbsp;</th>
                        </tr>
                      </thead>
                    </table>
                    <div className="form-group txt">
                <button
                className="form_btn"
                      type="button"
                      onClick={() => {
                        handleAddStep();
                      }}
                    >
                      New SubApp
                    </button>
                </div>
                  </div>
                </div>
          )
        }
      </div>
    );
  };
  return (
    <div>
      <Sidebar />
      {createForm()}
    </div>
  );
};

export default CreateApplication;

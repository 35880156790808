import React from "react";

export default function ProjectOverview(props) {
  const baseURL =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PROD_BASE_URL
      : process.env.REACT_APP_DEV_BASE_URL;

  const videoPath = `${baseURL}${props.video}`;

  const getLink = (value) => {
    const urlRegex = /(https:\/\/[^\s]+)/g;
    const parts = value.split(urlRegex);
    const elements = parts.map((part, index) => {
      if (index % 2 === 0) {
        return <span key={index}>{part}</span>; // Non-link part
      } else {
        return (
          <a key={index} href={part} target="_blank">
            {part}
          </a> // Link part
        );
      }
    });

    // Render the elements in your component
    return <p>{elements}</p>;
  }

  return (
    <div>
      <div className="video_section row">
        <div className="container">
          {props.video && (
            <div className="play_video col-12">
              <div className="col-5">
                <video
                  width="100%"
                  height="250"
                  controls
                  autoPlay={false}
                  preload="auto"
                >
                  <source src={videoPath} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className=" description_style col-6">
              {props.description !== undefined && getLink(props.description)}
              </div>
            </div>
          )}

          {!props.video && (
            <div className="play_video">
              {props.description !== undefined && getLink(props.description)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
import React, { Component } from 'react'
import aboutServicesImg from '../../images/about_services.png'

class ProfessionalServices extends Component {
  render() {
    return (
        <div className="professional_services row">
        <div className="container">
            <div className="col-7 wow slideInLeft">
                <figure>
            <img src={aboutServicesImg} alt=""/>
                    </figure>
            </div>
            <div className="col-5">
            <div className="services_list">
                
                <ul>
                    <li><span>1</span>
                        <div className="right_content wow slideInRight">
                        <h5 className="fs-18">Professional services</h5>
                    <p className="fs-16">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                            </div>
                    </li>
                    <li><span>2</span>
                        <div className="right_content wow slideInRight">
                        <h5 className="fs-18">Safe & Security</h5>
                    <p className="fs-16">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                            </div>
                    </li>
                    <li><span>3</span>
                        <div className="right_content wow slideInRight">
                        <h5 className="fs-18">Live Support</h5>
                    <p className="fs-16">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                            </div>
                    </li>
                </ul>
                </div>
            </div>
            </div>
        </div>
    )
  }
}

export default ProfessionalServices
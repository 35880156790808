import React from "react";
import { Link } from "react-router-dom";
const Project = (props) => {
  const navigateToURL = () => {
    if (props.url) {
      window.location.href = props.url; 
    }
  };
  return (
    <div>
      <div  onClick={navigateToURL}  className="col-4 box_design wow fadeInUp">
      <Link to={props.url}><em className="fa fa-caret-right"></em></Link>
        <figure>
          <img src={props.image_path} alt="" />
        </figure>
        <h4>{props.projectName}</h4>
        <p>{props.projectDescription}</p>
      </div>
    </div>
  );
};

export default Project
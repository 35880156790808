import React, { Component } from 'react'

import dataPreparation from '../../images/data_preparation.png'
import aiDevelopment from '../../images/aI_development.png'
import digitalEcommerce from '../../images/digital_ecommerce.png'
import consumerTechnology from '../../images/consumer_technology.png'
import bigDataService from '../../images/big_data_service.png'





export default class ServicesSolution extends Component {
    render() {
        return (
            <div className="services_solution row">
                <div className="container">
                    <div className="row wow slideInLeft">
                        <div className="col33 b_Shadow">
                            <figure><img src={dataPreparation} alt="" /></figure>
                            <h4 className="pB5 fs-22">Data Preparation</h4>
                            <p>Dummy text is also used to demonstrate the appearance of different. consultants opt in to the projects.</p>
                        </div>
                        <div className="col33 b_Shadow">
                            <figure><img src={aiDevelopment} alt="" /></figure>
                            <h4 className="pB5 fs-22">AI Development</h4>
                            <p>Dummy text is also used to demonstrate the appearance of different. consultants opt in to the projects.</p>
                        </div>
                    </div>
                    <div className="row  wow slideInRight">
                        <div className="col33">
                            <figure><img src={digitalEcommerce} alt="" /></figure>
                            <h4 className="pB5 fs-22">Digital Ecommerce</h4>
                            <p>Dummy text is also used to demonstrate the appearance of different. consultants opt in to the projects.</p>
                        </div>
                        <div className="col33 b_Shadow">
                            <figure><img src={consumerTechnology} alt="" /></figure>
                            <h4 className="pB5 fs-22">Consumer Technology</h4>
                            <p>Dummy text is also used to demonstrate the appearance of different. consultants opt in to the projects.</p>
                        </div>
                        <div className="col33">
                            <figure><img src={bigDataService} alt="" /></figure>
                            <h4 className="pB5 fs-22">Big Data Service</h4>
                            <p>Dummy text is also used to demonstrate the appearance of different. consultants opt in to the projects.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

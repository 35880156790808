import React from 'react';
import Slider from 'react-slick';
import { Link } from "react-router-dom";
import banner from '../../images/banner.png';
import './Banner.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";


const Banner = () => {

	const particlesInit = useCallback(async engine => {
		await loadFull(engine);
	}, []);

	const particlesLoaded = useCallback(async container => {
	}, []);
	const renderCarouselItem = () => {
		const carouselItems = [
			{
				title: 'Hands ',
				title_span: 'on with AI',
				description: 'Dive in to interactive demos that showcase a selection of the capabilities of the TFT AI platform.',
				buttonText: 'Explore Demo',
				active: true
			},
			// Add more carousel items as needed
		];

		return carouselItems.map((item, index) => (
			<div className={`item ${item.active ? 'active' : ''}`} key={index}>
				<div className="banner_txt wow fadeInRight">
					<h3>
						{item.title} <span>{item.title_span}</span>
					</h3>
					<p>{item.description}</p>
					<div className="action_btn">
						<ul>
							<li>
							<Link to="/" className="active">
									{item.buttonText}
							</Link>
							</li>
							<li>
								<Link to="/contact" className="active">
									Contact Us
								</Link>
							</li>
						</ul>
					</div>
				</div>
				<div className="banner_img wow slideInRight">
					<img src={banner} alt="" />
				</div>
			</div>
		));
	};

	// this object is use in Slider to modify the speed and autoplay
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
	};

	// use to handle the particle animation 
	const partcleOptions = {
		background: {
			color: {
				value: "#ffffff",
			},
		},
		fullScreen: {
			enable: false,
		},
		fpsLimit: 120,
		interactivity: {
			events: {
				onClick: {
					enable: true,
					mode: "push",
				},
				onHover: {
					enable: true,
					mode: "repulse",
				},
				resize: true,
			},
			modes: {
				push: {
					quantity: 4,
				},
				repulse: {
					distance: 200,
					duration: 0.4,
				},
			},
		},
		particles: {
			color: {
				value: "#cdced4",
			},

			collisions: {
				enable: true,
			},
			move: {
				direction: "none",
				enable: true,
				outModes: {
					default: "bounce",
				},
				random: false,
				speed: 3,
				straight: false,
			},
			number: {
				density: {
					enable: true,
					area: 800,
				},
				value: 150,
			},
			opacity: {
				value: 0.5,
			},
			shape: {
				type: "circle",
			},
			size: {
				value: { min: 1, max: 5 },
			},
		},
		detectRetina: true,
	};

	return (

		<div className="banner">
			<Particles
				id="tsparticles"
				init={particlesInit}
				loaded={particlesLoaded}
				style={{
					maxHeight: '90%',
					position: "absolute"
				}}
				options={partcleOptions}
			/>

			<div className="banner_content">
				<div className="container">
					<Slider {...settings} className="owl-carousel owl-theme" id="homebanner">
						{renderCarouselItem()}
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default Banner;

import { toast } from "react-toastify";
import { AxiosBase } from "./AxiosBase";

export default class UserlistService extends AxiosBase {
  getuserList = async () => {
    try {
      const response = await this.requests.getAuth("/user/get/users/");
      return response;
    } catch (err) {
      return;
    }
  };

  getEnquiryDetails = async (pageNo) => {
    try {
      const response = await this.requests.getAuth(
        `/dashboard/enquiriesdatapages/?page=${pageNo}`
      );
      return response;
    } catch (err) {
      console.error(err);
      return;
    }
  };

  postEnquiryStatus = async (payload) => {
    try {
      const response = await this.requests.postAuth("/dashboard/updateenquirystatus/", payload);
      if (response) {
        toast.success(response.data.result, {
          autoClose: 2000, 
        });
      }
      return response;
    } catch (err) {
      console.error(err);
      toast.error(err);
      return;
    }
  };

  // fetch dashboard stats
  getDashboardStats = async () => {
    try {
      const response = await this.requests.getAuth(
        "/dashboard/analytics_summary/"
      );
      return response;
    } catch (err) {
      throw err;
    }
  };
  // postEnquiryStatus = async (payload) => {
  //   try {
  //     const response = await this.requests.postAuth("/dashboard/updateenquirystatus/", payload);
  //     if (response) {
  //       toast.success("status added successfully", {
  //         autoClose: 2000, 
  //       });
  //     }
  //     return response;
  //   } catch (err) {
  //     console.error(err);
  //     toast.error(err);
  //     return;
  //   }
  // };

  // Add or register user
  addUser = async (payload) => {
    try {
      const response = await this.requests.postAuth("/user/register/", payload);
      if (response) {
        toast.success("User added successfully", {
          autoClose: 2000, 
        });
      }
      return response;
    } catch (err) {
      console.error(err);
      toast.error(err);
      return;
    }
  };
}

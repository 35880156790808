import { AxiosBase } from "./AxiosBase";

export default class ProjectService extends AxiosBase {
  getPublishedProjects = async () => {
    try {
      const response = await this.requests.get("/applications/published/");
      return response;
    } catch (err) {
      console.error(err);
      return;
    }
  };
  getAllProjects = async () => {
    try {
      const response = await this.requests.get("/applications/");
      return response;
    } catch (err) {
      console.error(err);
      return;
    }
  };
  getProjectDetails = async (projectId) => {
    try {
      const response = await this.requests.get(
        `/applications/featuredetail/${projectId}/`
      );
      return response;
    } catch (err) {
      console.error(err);
      return;
    }
  };
  getProjectSteps = async (projectId, stepId) => {
    try {
      const response = await this.requests.get(
        `/applications/feature/${projectId}/${stepId}/`
      );
      return response.data;
    } catch (err) {
      console.error(err);
      return;
    }
  };
  submitFormData = async (formData) => {
    try {
      const response = await this.requests.post(
        `/applications/stepresponse/`,
        formData,
        true
      );
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };
}

import { toast } from "react-toastify";
import { removeStorageItem, setStorageItem } from "../utils/session";
import { AxiosBase } from "./AxiosBase";
import { setLoading, setLoggedIn, setUserRole } from "../slices/global";
import { setEmptyEnquiryList } from "../slices/admin";

export default class AuthService extends AxiosBase {
  login = async (formData, dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await this.requests.post(
        `/api-token-auth/`,
        formData,
        true
      );
      if (response) {
        dispatch(setLoggedIn(true));
        dispatch(setUserRole(response.role));
        setStorageItem("access_token", response.access);
        setStorageItem("refresh_token", response.refresh);
        setStorageItem(
          "access_expires_in",
          new Date().getTime() + 1000 * 60 * 60 * 24
        );
        setStorageItem(
          "refresh_expires_in",
          new Date().getTime() + 1000 * 60 * 60 * 24 * 6
        );
        toast.success("logged in successfully", {
          autoClose: 2000, 
        });
      }
      return response;
    } catch (err) {
      toast.error(err);
      return;
    } finally {
      dispatch(setLoading(false));
    }
  };

  changePassword = async (payload, dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await this.requests.patchAuth(
        `/user/change/password/`,
        payload
      );
      console.log(response);
      if (response) {
        toast.success("Password changed successfully",{
          autoClose: 2000, 
        });
      }
    } catch (err) {
      toast.error(err.data.detail);
    } finally {
      dispatch(setLoading(false));
    }
  };

  logout = async (dispatch) => {
    try {
      dispatch(setLoading(true));
      dispatch(setLoggedIn(false));
      dispatch(setUserRole(""));
      dispatch(setEmptyEnquiryList());
      removeStorageItem("access_token");
      removeStorageItem("refresh_token");
      removeStorageItem("access_expires_in");
      removeStorageItem("refresh_expires_in");
      toast.success("logged out successfully", {
        autoClose: 2000,
      });
      dispatch(setLoading(false));
    } catch (err) {
      console.log(err);
      toast.error("error while logging out", {
        autoClose: 2000, 
      });
    }
  };
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about_section {
  padding: 50px 0px;
}
.about_section p {
  line-height: 25px;
}`, "",{"version":3,"sources":["webpack://./src/Components/AboutSection/AboutSection.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;AACD;AAAC;EAAE,iBAAA;AAGH","sourcesContent":[".about_section{\n\tpadding: 50px 0px;\n\tp{line-height: 25px;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

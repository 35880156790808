// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*@media only screen and (max-width: \$large) {}
@media only screen and (max-width: \$medium) {}
@media only screen and (max-width: \$small) {}*/`, "",{"version":3,"sources":["webpack://./src/scss/helpers/_media.scss"],"names":[],"mappings":"AAcA;;8CAAA","sourcesContent":["//media variables\r\n\r\n$m320:320px;\r\n$m340:340px;\r\n$m360:360px; // Galaxy Phones\r\n$m375:375px;\r\n$m411:411px; //pixel phones\r\n$m480:480px;  // Iphpne 8\r\n$small:540px;\r\n$medium:720px;\r\n$ipad:768px;\r\n$large:1024px;\r\n\r\n\r\n/*@media only screen and (max-width: $large) {}\r\n@media only screen and (max-width: $medium) {}\r\n@media only screen and (max-width: $small) {}*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

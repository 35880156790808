import React, { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "../Sidebar/Sidebar";
import { Formik, ErrorMessage, Field, Form } from "formik";
import * as Yup from "yup";
import "../styles.scss";
import ApplicationsService from "../../../services/ApplicationsService";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "../../../store";
import editIcon from "../../../images/edit.svg";
import deleteIcon from "../../../images/delete.svg";
import { useEffect } from "react";
import { getProjectDetails } from "../../../slices/projects";
import ConfirmBox from "../ConfirmBox/ConfirmBox";

const applicationsService = new ApplicationsService();
const EditApplication = () => {
  const { featureName, featureId } = useParams();
  const dispatch = useDispatch();
  const [removeVideo, setRemoveVideo] = useState(true);
  const navigate = useNavigate();
  const { projectDetails } = useSelector((state) => state.projects);
  const imageRef = useRef();
  const videoRef = useRef();
  const handledeletePopupRef = useRef()
  const deletestepNameRef = useRef();
  const [isDetelePopup, setisDetelePopup] = useState(false)
  const [file, setFile] = useState({ featureIcon: "", featureVideo: "" });
  const baseUrl = process.env.REACT_APP_SERVER_URL;



  useEffect(() => {
    dispatch(getProjectDetails(featureId));
  }, [dispatch, featureName, featureId]);

  let initialValues = {
    featureKey: "",
    featureLabel: "",
    featureIcon: "",
    featureVideo: "",
    authKey: "",
    authType: "",
    featureDescription: "",
    is_published:"",
  };

  if (projectDetails) {
    initialValues.featureKey = projectDetails.featureName;
    initialValues.featureLabel = projectDetails.featureSlug;
    initialValues.featureIcon = projectDetails.featureImagePath;
    initialValues.featureVideo = projectDetails.featureVideoPath;
    initialValues.authKey = projectDetails.authKey;
    initialValues.authType = projectDetails.authType;
    initialValues.featureDescription = projectDetails.featureDescription;
    initialValues.is_published= projectDetails.is_published;
  }

  const validationSchema = Yup.object().shape({
    featureKey: Yup.string(),
    featureLabel: Yup.string().required("Application Label is required"),
    featureDescription: Yup.string().required("Application description is required"),
  });

  const handleSave = async (values) => {
    const { featureKey, featureLabel, authKey, authType, featureDescription,is_published} =
      values;

    if (featureKey && featureLabel) {
      const formData = new FormData();
      formData.append("featureId", featureId);
      formData.append("featureName", featureKey);
      formData.append("featureSlug", featureLabel);
      formData.append("authKey", authKey);
      formData.append("authType", authType);
      formData.append("featureDescription", featureDescription);
      if (file.featureIcon) {
        formData.append("featureImage", file.featureIcon);
      } else {
        formData.append("featureImage", initialValues.featureIcon);
      }
      if (file.featureVideo) {
        formData.append("featureVideo", file.featureVideo);
      } else if (!removeVideo) {
        formData.append("featureVideo", "");
      }
      else{
        formData.append("featureVideo", initialValues.featureVideo);
      }
      formData.append("is_published", is_published ? "True" : "False");
      try {
        const response = await applicationsService.updateApplication(
          formData,
          dispatch
        );
        if (response && response.Success === true) {
          initialValues.is_published = response.data.is_published;
          toast.success("Application updated successfully", {
            autoClose: 2000}); 
        } else if (response && response.Success === false) {
          toast.error(response.data.result);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const handleDeletePopupData = () => {
    setisDetelePopup(!isDetelePopup);
  }
  const handleDeletPopup = (stepName) => {
    setisDetelePopup(!isDetelePopup);
    deletestepNameRef.stepName = stepName;
  }
  handledeletePopupRef.handleDeletePopup = handleDeletePopupData

  const handleDelete = async (stepName) => {
    if (!stepName || !featureName) return;
      const payload = {
        stepName,
        featureName
      }
      try {
        const response = await applicationsService.deleteSteps(payload, dispatch);
        if (response) {
          toast.success('SubApp deleted successfully', {
            autoClose: 2000, 
          });
          dispatch(getProjectDetails(featureId));
        }
      }
      catch (error) {
          toast.errror(error)
      }
      handleDeletePopupData();
  };

  const handleAddStep = (stepId = false) => {
    if (stepId) {
      navigate(`/controlpanel/application/${featureName}/${featureId}/step/${stepId}`);
    } else {
      navigate(`/controlpanel/application/${featureName}/${featureId}/step/new`);
    }
  };
  const handleFileChange = (event) => {
    const targetName = event.target.name;
    const file = event.target.files[0];
  
    setFile((prevFile) => {
      const updatedFile = {
        ...prevFile,
        [`${targetName}File`]: file,
      };
  
      if (targetName === 'featureIcon') {
        updatedFile.featureIcon = file;
      } else if (targetName === 'featureVideo') {
        updatedFile.featureVideo = file;
      }
  
      return updatedFile;
    });
  };
  const handleRemoveVideo = () => {
    setRemoveVideo(false);
  };
  return (
    <div>
      <Sidebar />
      <div className="mainbox">
        <h1>Edit Application</h1>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="row">
                <div className="form-group">
                  <label htmlFor="">
                    Application Key <span className="error_message"> *</span>
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    name="featureKey"
                    placeholder="Application Key"
                    readOnly
                  />
                  <ErrorMessage
                    name="featureKey"
                    component="div"
                    className="error_message"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">
                    Application Label <span className="error_message"> *</span>
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    name="featureLabel"
                    placeholder="Application Label"
                  />
                  <ErrorMessage
                    name="featureLabel"
                    component="div"
                    className="error_message"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <label htmlFor="">Application icon</label>
                  <div className="file-group">
                  <input
                    type="file"
                    // className="form-control"
                    name="featureIcon"
                    ref={imageRef}
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                  </div>
                  <ErrorMessage
                    name="featureIcon"
                    component="div"
                    className="error_message"
                  />
                  {file.featureIcon ? (
                    <img
                    className="custom-image"
                      src={URL.createObjectURL(file.featureIcon)}
                      alt="Uploaded Icon"
                      style={{ maxWidth: "100%", maxHeight: "150px" }}
                    />
                  ) : (
                    <img
                      className="custom-image"
                      src={baseUrl + initialValues.featureIcon}
                      alt="Application Icon"
                    />
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="">Application Video</label>
                  <div className="file-group">
                  <input
                    type="file"
                    // className="form-control"
                    name="featureVideo"
                    ref={videoRef}
                    onChange={handleFileChange}
                    accept="video/*"
                  />
                  </div>
                  {file.featureVideo && (
                    <video
                      controls
                      className="custom-video"
                      onError={(e) => {
                        e.target.style.display = "none";
                        console.error("Video could not be loaded.");
                      }}
                    >
                      <source
                        src={URL.createObjectURL(file?.featureVideo)}
                        type="video/mp4"
                      />
                    </video>
                  )}
                  {!file.featureVideo &&
                    initialValues.featureVideo &&
                    removeVideo && (
                      <div>
                        <span style={{cursor:"pointer"}} onClick={handleRemoveVideo}> &times;</span>
                        <div>
                          <video controls className="custom-video">
                            <source
                              src={baseUrl + initialValues.featureVideo}
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <label htmlFor="">Auth Type</label>
                  <Field
                    type="text"
                    className="form-control"
                    name="authType"
                    placeholder="Auth Type"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">Auth Key</label>
                  <Field
                    type="text"
                    className="form-control"
                    name="authKey"
                    placeholder="Auth Key"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <label htmlFor="">
                    Description<span className="error_message"> *</span>
                  </label>
                  <Field
                    type="textarea"
                    className="form-control"
                    name="featureDescription"
                    placeholder="Description"
                  />
                  <ErrorMessage
                    name="featureDescription"
                    component="div"
                    className="error_message"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <label htmlFor="">Published</label>
                  <Field type="checkbox" name="is_published" className="publish-button"/>
                </div>
              </div>
              <div>
                <div className="row">
                  <div className="form-group txt">
                    <h3>SubApp</h3>
                  </div>
                  <div className="row tableBox N">
                    <table>
                      <thead>
                        <tr>
                          <th className="name-sn">S.N.</th>
                          <th className="name-column">Name</th>
                          <th className="action-column">Action</th>
                          <th width="39%">&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {projectDetails &&
                          projectDetails?.steps?.length > 0 &&
                          projectDetails?.steps?.map((item, index) => (
                            <tr key={item.stepId}>
                              <td>{index + 1}</td>
                              <td>{item.stepName}</td>
                              <td className="action-button">
                                <div
                                  className="btn"
                                  onClick={() => {
                                    handleAddStep(item.stepId);
                                  }}
                                >
                                  <em>
                                    <img src={editIcon} alt="" />
                                  </em>
                                </div>
                              {/* </td>
                              <td > */}
                                <div
                                  className="btn"
                                  onClick={() => {
                                    handleDeletPopup(item.stepName);
                                  }}
                                >
                                  <em>
                                    <img src={deleteIcon} alt="" />
                                  </em>{" "}
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="form-group txt">
                  <button
                  className="form_btn"
                    type="button"
                    onClick={() => {
                      handleAddStep();
                    }}
                  >
                    New SubApp
                  </button>
                </div>
              </div>
              <div className="form-group submit">
                <button
                  className="form_btn"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Saving..." : "Save"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {isDetelePopup ? (
        <ConfirmBox
          handleDeleteData={handleDelete}
          handleDeletePopup={handledeletePopupRef.handleDeletePopup}
          featureId={deletestepNameRef.stepName}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default EditApplication;

import React from "react";
import "../scss/styleNew.scss";
import homeimg from "../images/newImages/homeimg.png";
import benefitImg from "../images/newImages/benifit-img.png";
import picture2 from "../images/newImages/Picture2.png";
import logoClient from "../images/newImages/logo-client.png";
import Footer from "../Components/Footer/Footer";
import Nav from "../Components/NavBar/Nav";
import Testimonial from "../Components/Carousel/Testimonial";
import ConnectForm from "../Components/Connect/ConnectForm";
import TFTaiSolutions from "../images/adminimages/tftai_solutions.mp4"

const HomePageNew = () => {

	const settings = {
		dots: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};
    
  return (
    <div>
      <Nav />
      {/* main-banner  */}
      <div className="main-banner" style={{ marginTop: "80px" }}>
        <div className="continer">
          <div className="leftbox">
            <h1 className="heading-sy">
              <span>
                TFT<small>.AI</small>
              </span>
              Harness the Power of TFT.AI Platform to Accelerate Your Business
            </h1>
            <p>
              Explore the cutting-edge business transformation with TFT.AI
              Platform, where the fusion of machine cognition and human
              oversight drives strategic decision-making for organizations.
            </p>
          </div>
          <figure>
            <img src={homeimg} alt="" />
          </figure>
        </div>
        <p className="last">
          TFT.AI was founded with a clear mission: To develop a Do It Yourself
          (DIY) AI platform that empowers organizations to leverage cognitive
          capabilities, harnessing the power of advances in NLP and Generative
          AI. This strategic adoption not only drives unparalleled efficiencies
          but also secures a vital competitive edge in business processes.
        </p>
      </div>
      {/* benefit section  */}
      <section className="benifit">
        <div className="continer">
          <h3 className="heading-sy">
            How does TFT<span>.AI</span> Platform work?
          </h3>
          <p>
            <video width="100%" controls>
              <source src={TFTaiSolutions} />
            </video>
          </p>
          <p style={{marginTop:"10px"}}>
            TFT.AI envisions an administrative interface that simplifies the
            addition of features and steps, making them readily accessible to
            platform users via an API.This platform serves as a gateway for
            presenting AI/ML applications, referred to as - Features.  
          </p>
          <p style={{marginTop:"10px"}}>It offers
            a user-friendly interface to access various AI/ML REST API services.
            Through this platform, end-users can actively engage and explore the
            outcomes of intermediary processes, known as - Steps. </p>

          <div>
            <ul className="benefit-list">
              <li>
                <span className="benefits-step">Features:</span> End-to-end functionality comprising different steps in
                a specified sequential order.
              </li>
              <li><span className="benefits-step">Steps:</span> Small functionality exposed through the platform.</li>
            </ul>
          </div>
          <figure>
            <img src={benefitImg} alt="" />
          </figure>
        </div>
      </section>
      {/* Advantage section  */}
      <section className="advantage">
        <div className="continer">
          <h4 className="heading-sy">
            The TFT<span>.AI</span> Advantage
          </h4>
          <div className="col-12 row" >
            <div className="col-6">
          <ul>
            <li>
              <span>1</span>{" "}
              <small>
                Access a vast array of business use cases as features with the
                option to choose from various steps.
              </small>
            </li>
            <li>
              <span>2</span>{" "}
              <small>Choose different ML models for a feature or step.</small>
            </li>
            <li>
              <span>3</span>{" "}
              <small>
                Compare accuracy and speed metrics of various ML models.
              </small>
            </li>
            <li>
              <span>4</span>{" "}
              <small>Add new features and steps effortlessly.</small>
            </li>
            </ul>
            </div>
            <div className="col-6">
            <ul>
            <li>
              <span>5</span>{" "}
              <small>
                Gather feedback on solved use cases for continuous improvement.
              </small>
            </li>
            <li>
              <span>6</span> <small>Enable human audits of each step.</small>
            </li>
            <li>
              <span>7</span>{" "}
              <small>
                Facilitate human intervention and oversight of AI-generated
                responses and content.
              </small>
            </li>
            <li>
              <span>8</span>{" "}
              <small>
                Train your organization's data locally in a federated manner.
              </small>
            </li>
          </ul>
          </div>
          </div>
        </div>
      </section>
      {/* usecase  */}
      <section className="usecase">
        <div className="continer">
          <h4 className="heading-sy">Use Cases</h4>
          <ul>
            <li>Cognitive RPA - Automation Twin</li>
            <li>Multilingual OCR and Handwriting Recognition</li>
            <li>
              Chatbots & FAQBot{" "}
            </li>
            <li>AI in Testing and Automation</li>
            <li>Live Legal Transcription</li>
          </ul>
        </div>
      </section>
      {/* connectnow  */}
      <section className="connectnow">
        <div className="continer">
          <h4 className="heading-sy">
            TFT<span>.AI</span> Capabilities
          </h4>
          <figure>
            <img src={picture2} alt="" />
          </figure>
        </div>
      </section>

      <section className="connectnow">
        <div className="continer">
          <h4 className="heading-sy">
            TFT<span>.AI</span> Techstack
          </h4>
          <figure className="image-techstack">
            <img src={logoClient} alt="" />
          </figure>
        </div>
      </section>
      {/* testimonial  */}
      <div className="testimonial" style={{marginBottom : "2rem"}}>
        <div className="continer">
          <h3 className="heading-sy">Testimonial</h3>
            {/* <Slider {...settings}> */}
            <div>
              <Testimonial />
            </div>
            {/* </Slider> */}
        </div>
      </div>
      {/* register  */}
      <section className="register">
        <div className="container">
          <p>
            Join us on this exciting journey as we reshape the future of
            business through the power of AI with TFT.AI
          </p>
          {/* <p className="registerText">Register today and embark on a journey of transformation.</p> */}
          <p>Register today and embark on a journey of transformation.</p>
          <a href="/contact">
          Register
          </a>
        </div>
      </section>
      {/* contact form  */}
      <section>
      <ConnectForm />
      </section>
      <Footer />
    </div>
  );
};

export default HomePageNew;

import React from "react";
import { useLocation } from "react-router-dom";
import Project from "./Project";
import { Link } from "react-router-dom";

const ProjectsList = ({ projectList, loading }) => {
  const location = useLocation();
  // const numberOfProjectsToShow = location.pathname === "/" ? 3 : projectList?.length; // {commenting for now making solutions page as homepage}
  return (
    <>
      <div className="our_ai_project row rm-mar30">
        <div className="container">
          <h3 className="t_center heading_style wow slideInLeft">
            Our AI <span>Solutions</span>
          </h3>
            {projectList?.length > 0 ? (
            projectList
            // .slice(0, numberOfProjectsToShow) //{commenting for now making solutions page as homepage}
            .map((item, key) => (
              <Project
                key={key}
                image_path={item.image_path}
                projectName={item.featureSlug}
                projectDescription={item.featureDescription}
                btnText="Learn More"
                url={item.enabled ? `/solutions/${item.featureName}/${item.featureId}` : ""}
              />
            ))
          ) : (
            <h1>No Solutions Avaialble</h1>
          )}
          {loading && <h1>Loading </h1>}
        </div>
        {/* {location.pathname === "/" && (
          <div className="d_btn t_center">
            <Link to='/solutions'>View All Solutions</Link>
          </div>
        )} */} {/* {commenting for now making solutions page as homepage} */}
      </div>
    </>
  );
};

export default ProjectsList;

import { AxiosBase } from './AxiosBase';

export default class ContactService extends AxiosBase {
  submitFormData = async (formData) => {
    try {
      const response = await this.requests.post(`/contactus/`, formData, true);
      return response;
    } catch (err) {
      console.error(err);
      return;
    }
  }

  getSelectFieldOptions = async() => {
    try {
      const response = await this.requests.post(`/contactus/sources/`);
      return response;
    } catch (err) {
      console.error(err);
      return;
    }
  }
}

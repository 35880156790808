import React, { useState, useEffect } from 'react';

function Version() {
  const [fileContent, setFileContent] = useState('');
  const fileURL = "../../temp_build_info.json"

  useEffect(() => {
    fetch(fileURL)
      .then((response) => response.text()) // Read the response as text
      .then((data) => {
        setFileContent(data); // Set the file content in state
      })
      .catch((error) => console.error('Error:', error));
  }, []);

  return (
    <pre>{fileContent}</pre>
  );
}

export default Version;

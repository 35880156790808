import React from 'react'
import "../../Admin/styles.scss";

export default function ConfirmBox({ handleDeleteData, handleDeletePopup, featureId }) {
  const handleDeleteAction = async () => {
    handleDeleteData(featureId);
  }
  const handleCancel = () => {
    handleDeletePopup();
  }
  return (
    <div className='popupoverlay'>

      <div className="popupbox confirm">
        <h3>Delete</h3>
        <p className="ctxt">Are you sure want to delete this item?</p>
        <div className="act-btn">
          <ul>
            <li><button className="cancel" onClick={handleCancel}>Cancel</button></li>
            <li><button className="delete" onClick={handleDeleteAction}>Delete</button></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

import React from "react";
import "./loader.scss";
import { useSelector } from "react-redux";

function TransparentLoader({ children }) {
  const { loading } = useSelector((state) => state.global);
  return (
    <div
      className="TransparentLoaderWrapper"
      style={{ pointerEvents: loading ? "none" : "initial" }}
    >
      {children}
      {loading && (
        <div className="ProgressWrapper">
          <div
            className="CircularProgress"
          ></div>
        </div>
      )}
    </div>
  );
}
export default TransparentLoader;

import React, { useEffect, useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import "./WebCam.scss";

export default function AudioRecorder({ getErrorMsg, item, handleRecord }) {
  const { status, startRecording, stopRecording, mediaBlobUrl, mediaBlob } =
    useReactMediaRecorder({ audio: true, video: false });

  const [isRecording, setIsRecording] = useState(false);
  const [showAudio, setShowAudio] = useState(false);
  const [recordedAudioFile, setRecordedAudioFile] = useState(null);

  const handleStartRecording = () => {
    startRecording();
    setIsRecording(true);
  };

  const handleStopRecording = () => {
    stopRecording();
    setIsRecording(false);
    setShowAudio(true);

    // Store the mediaBlob as a file in state
    setRecordedAudioFile(new File([mediaBlob], "recorded-audio.wav"));
  };

  const handleRetake = () => {
    mediaBlobUrl && window.URL.revokeObjectURL(mediaBlobUrl);
    setIsRecording(false);
    setShowAudio(false);
    setRecordedAudioFile(null);
    handleRecord(recordedAudioFile, item, true)
  };

  useEffect(() => {
    if(recordedAudioFile) {
      handleRecord(recordedAudioFile, item)
    }
  }, [recordedAudioFile])

  return (
    <div className="webcamContainer Container">
      <h4>
        {item.label}
        {item.optional === "false" && " * "}
        <span className="required">{" " + getErrorMsg(item.key)}</span>
      </h4>
      <div className="upload_box row">
        <div className="file_container">
          {isRecording ? (
            <div>
              <p>Recording...</p>
              <button className="webcam-btn" onClick={handleStopRecording}>Stop Recording</button>
            </div>
          ) : !showAudio ? (
            <div>
              <p>{status}</p>
              <button className="webcam-btn"  onClick={handleStartRecording}>Start Recording</button>
            </div>
          ) : (
            ""
          )}
          {showAudio && mediaBlobUrl && (
            <div>
              <audio className="audio" src={mediaBlobUrl} controls />
              <div className="recordBtnContainer">
                <button className="webcam-btn" onClick={handleRetake}>Retake</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

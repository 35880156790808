import React, { useEffect,useState } from "react";
import { Sidebar } from "../Sidebar/Sidebar";
import { getuserList } from "../../../slices/admin";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddUser from "./AddUser";
import { useRef } from "react";

const Users = () => {
  const { userList } = useSelector((state) => state.admin);
  const [isAdduser, setisAdduser] = useState(false);
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.global);
  const navigate = useNavigate();
  const adduserRef = useRef();
  useEffect(() => {
    dispatch(getuserList());
    if (!isLoggedIn) {
      navigate("/controlpanel");
    }
  }, [isLoggedIn,isAdduser,dispatch,navigate]);

  const handleAddUserPopUp = () => {
    setisAdduser(!isAdduser);
  }
  adduserRef.showPopup = handleAddUserPopUp

  return (
    <div>
      <Sidebar />
      <div className="mainbox">
        <h1>User List <a onClick={handleAddUserPopUp}>+ Create New</a></h1>
        
        <table>
          <thead>
            <tr>
              <th>S.N.</th>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Username</th>
            </tr>
          </thead>
          <tbody>
            {userList && userList.length > 0 && userList?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.email}</td>
                  <td>{item.name}</td>
                  <td>{item.role}</td>
                  <td>{item.user_name}</td>
                </tr>
              );
            })}
          </tbody>
          {userList && userList.length === 0 ? "No Users" : ""}
        </table>
      </div>{" "}
      {
        isAdduser? <AddUser handlePopup={adduserRef.showPopup}/>: <></>
      }
    </div>
  );
};

export default Users;

import React, { Component } from 'react'
import './FaceRecog.scss'
import faceImage from '../../images/face.svg'

class FaceRecog extends Component {
    render() {
        return (
            <>
                <div className="face_recognition row">
                    <div className="container">
                        <div className="fr_left_section">
                            <div className="heading_left">
                                <h2 className="wow slideInLeft">Face <span>Recognition</span></h2>
                                <p>TFT Face recognition can rapidly analyze and compare, deciding whether two photographs, or a photograph and a video are of the same individual or not, and locate features like eyes and smile on a uploaded photograph or video.</p>

                            </div>
                            <figure className="wow fadeInDown"><img src={faceImage} alt="faceRecognization Image" /></figure>
                            <div className="d_btn">
                                <a className="default_btn" href="javascript:void(0)">View Demo</a>
                            </div>
                        </div>
                        <div className="right_perfect_tool">
                            <h3 className="wow slideInRight">The perfect tool for any content</h3>
                            <ul>
                                <li><a>Face Recognition</a></li>
                                <li><a>Object Detection</a></li>
                                <li><a>Employee Pulse Document</a></li>
                                <li><a >Processing Explore</a></li>
                            </ul>
                            <p className='explore_projects_all'>Explore all tools</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default FaceRecog
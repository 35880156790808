import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ApplicationsService from "../../../../services/ApplicationsService";
import {
  getProjectDetails,
  getProjectSteps,
} from "../../../../slices/projects";
import { useSelector } from "../../../../store";
import { Formik, ErrorMessage, Field, Form } from "formik";
import editIcon from "../../../../images/edit.svg";
import deleteIcon from "../../../../images/delete.svg";
import { toast } from "react-toastify";
import { Sidebar } from "../../Sidebar/Sidebar";
import * as Yup from "yup";
import "../../styles.scss";
import ConfirmBox from "../../ConfirmBox/ConfirmBox";
import EditModal from "./EditModal";
import {BsFillArrowLeftCircleFill} from "react-icons/bs"


const applicationsService = new ApplicationsService();
const Step = () => {
  const { featureId, featureName, stepId } = useParams();
  const { projectSteps } = useSelector((state) => state.projects);
  const [projectStep, setprojectStep] = useState({
    stepName: null,
    stepSlug: null,
    // stepUseruploads: null,
    // stepText: null,
    stepSendText: null,
    stepApiPath: null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formElements, setFormElements] = useState([]);
  const [isDeletePopup, setisDeletePopup] = useState(false);
  const [isFieldDeletePopup, setisFieldDeletePopup] = useState(false);
  const [deletedFieldIndex, setdeletedFieldIndex] = useState();
  
  
  const [editModal, setEditModal] = useState(false)
  const [updateIndex, setUpdateIndex] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const [uploadModal, setUploadModal] = useState(false);
  const [updatedImageIndexList,setUpdatedImageIndexList] = useState([])

  

  useEffect(() => {
    if (stepId !== "new") {
      dispatch(getProjectSteps(featureId, stepId));
    }
  }, [dispatch, featureId, stepId]);
  
  useEffect(() => {
    let params = [];
    for (let i = 0; i < projectSteps?.params?.length; i++) {
      params.push(projectSteps.params[i]);
    }
    const stepData = {
      stepName: projectSteps?.stepName,
      stepSlug: projectSteps?.stepSlug,
      // stepUseruploads: projectSteps?.stepUseruploads,
      // stepText: projectSteps?.stepText,
      stepSendText: projectSteps?.stepSendText,
      stepApiPath: projectSteps?.stepApiPath
    };
    setprojectStep(stepData);
    setFormElements(projectSteps?.params || []);
  }, [projectSteps]);
  
  const setKeyValue = (field, value, index) => {
    const updatedFormElements = [...formElements];
  
    switch (value) {
      case "radio":
      case "selectbox":
        updatedFormElements[index] = {
          ...updatedFormElements[index],
          [field]: value,
          options: [{ label: '', value: '' }],
        };
        break;
  
      case "uploadfile":
        updatedFormElements[index] = {
          ...updatedFormElements[index],
          [field]: value,
          samples: [{ name: '', type: '', path: '' }],
        };
        break;
  
      default:
        updatedFormElements[index] = {
          ...updatedFormElements[index],
          [field]: value,
        };
    }
  
    setFormElements(updatedFormElements);
  };
  


  let initialValues = {
    // stepUseruploads: "",
    stepSendText: "",
    params: [],
    stepApiPath: [],
    stepName:"",
    stepSlug:"",
  };

  if (stepId !== "new" && projectSteps) {
    initialValues.stepName = projectSteps.stepName;
    initialValues.stepSlug = projectSteps.stepSlug;
    // initialValues.stepText = projectSteps.stepText;
    // initialValues.stepUseruploads = projectSteps.stepUseruploads;
    initialValues.stepSendText = projectSteps.stepSendText;
    initialValues.params = projectSteps.params;
    initialValues.stepApiPath = projectSteps.stepApiPath;
  }

  useEffect(() => {
    if (stepId === "new") {
      setFormElements([]); 
      return; 
    }
  }, [projectSteps, stepId]);

  const handleSave = async (values) => {
    const { stepName,stepSlug, stepSendText,stepApiPath 
      // stepText, stepUseruploads, 
    } =
      values;
    let params = formElements.map((element) => {
      const commonData = {
        key: element.key,
        label: element.label,
        optional: element.optional,
        type: element.type,
      };
    
      if (element.options && element.options.length > 0) {
        return {
          ...commonData,
          options: element.options,
        };
      } else if (element.samples && element.samples.length > 0) {
        return {
          ...commonData,
          samples: element.samples,
        };
      }
    
      return commonData;
    });

    params = JSON.stringify(params);

    if (stepName && featureName && featureId) {
      const formData = new FormData();
      if (stepId !== "new") {
        formData.append("stepId", stepId);
      }
      formData.append("stepName", stepName);
      formData.append("stepSlug", stepSlug);
      // formData.append("stepText", stepText);
      // formData.append("stepUseruploads", stepUseruploads);
      formData.append("stepSendText", stepSendText);
      formData.append("featureName", featureName);
      formData.append("params", params);
      formData.append("stepApiPath", JSON.stringify(stepApiPath));
      formData.append("featureId", featureId);
      if (stepId === "new") {
        try {
          const response = await applicationsService.addStep(
            formData,
            dispatch
          );
          if (response && response.Success === true) {
            toast.success("SubApp added successfully");
          } else if (response && response.Success === false) {
            toast.error(response.data.result);
          } else if (response &&  response.result != "") {
            toast.error(response.result)
          }
        } catch (error) {
          console.error("error", error);
        }
      } else {
        try {
          const response = await applicationsService.updateStep(
            formData,
            dispatch
          );
          if (response && response.Success === true) {
            toast.success("SubApp updated successfully",{
              autoClose: 2000}); 
          } else if (response && response.Success === false) {
            toast.error(response.data.result);
          }
        } catch (error) {
          console.error("error", error);
        }
      }
    }
  };

  const handleDeleteStepAPI = async () => {
    try {
      const data = { ...projectStep };
      data.stepApiPath = [];
      setprojectStep(data);
      toast.success("SubApp API path removed", {
        autoClose: 2000, 
      });
      handleDeleteStepPopup();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleAddStep = (stepId = false) => {
    if (stepId) {
      navigate(`/controlpanel/application/${featureName}/${featureId}/step/${stepId}`);
    } else {
      navigate(`/controlpanel/application/${featureName}/${featureId}/step/new`);
    }
  };
  const handleDeleteStepPopup = () => {
    setisDeletePopup(!isDeletePopup);
  };

  const handleDeleteFieldPopup = (index) => {
    setisFieldDeletePopup(!isFieldDeletePopup);
    setdeletedFieldIndex(index);
  };

  const handleAddPath = () => {
    setFormElements([
      ...formElements,
      {
        id: formElements.length + 1,
        key : `key${formElements.length + 1}`,
        label : `label${formElements.length + 1}`,
        optional: "false",
        type: "text box",
      },
    ]);
  };

  const handleRemoveField = (indexToRemove) => {
    const updatedFormElements = [...formElements];
    updatedFormElements.splice(indexToRemove, 1);
    setFormElements(updatedFormElements);
    setisFieldDeletePopup(!isFieldDeletePopup);
  };

  const validationSchema = Yup.object().shape({
    stepName: Yup.string().required("SubApp Name is required"),
    stepSlug: Yup.string().required("SubApp Slug is required"),
  });

  useEffect(() => {
    dispatch(getProjectDetails(featureId));
  }, [dispatch, featureId]);

  const handleAddInput = () => {
    const updatedFormElements = [...formElements];

    updatedFormElements[updateIndex].options = [
      ...updatedFormElements[updateIndex].options,
      {label: '', value : ''}
    ]

    setFormElements(updatedFormElements)

  };

  const handleInputChange = (index, event, name,) => {
    const updatedFormElements = [...formElements];
    const updatedOptions = [...updatedFormElements[updateIndex].options];

    updatedOptions[index] = {
      ...updatedOptions[index],
      [name]: event.target.value,
    };
  
    updatedFormElements[updateIndex] = {
      ...updatedFormElements[updateIndex],
      options: updatedOptions,
    };
    setFormElements(updatedFormElements);
  };
  
  const handleEdit = (data, elementIndex) => {
    const updatedFormElements = [...formElements];
    const getIndex = updatedFormElements.findIndex((data, index) => index === elementIndex);
    setEditModal(data.type === "radio" || data.type === "selectbox");
    setUploadModal(data.type === "uploadfile");
  
    if (getIndex !== -1) {
      setUpdateIndex(getIndex);
  
      if (data.type === "radio" || data.type === "selectbox") {
        if (data.options.length > 0) {
          updatedFormElements[getIndex] = {
            ...updatedFormElements[getIndex],
            options: data.options,
          };
        }
      } else if (data.type === "uploadfile") {
          setUpdatedImageIndexList((prevState) => {
          const filledIndexList = new Array(data.samples.length).fill(undefined);
          return filledIndexList;
        });
  
        if (data.samples !== undefined && data.samples.length > 0) {
          updatedFormElements[getIndex] = {
            ...updatedFormElements[getIndex],
            samples: data.samples,
          };
        }
      }
  
      setFormElements(updatedFormElements);
    }
  };
  


  const closeModal = () => {
    setEditModal(false);
    setUploadModal(false)
  }

  const handleDelete = (removeItem) => {
    const updatedFormElements = [...formElements];
    const options = updatedFormElements[updateIndex].options;
  
    if (options.length > 1) {
      updatedFormElements[updateIndex].options = options.filter((_, index) => index !== removeItem);
      setFormElements(updatedFormElements);
    }
    
  }

  const validateOptions = (options) => {
    // Check if any option has empty label or value
    return options.every((option) => option.label.trim() !== "" && option.value.trim() !== "");
  };
  
  const handleSubmit = () => {
    const updatedFormElements = [...formElements];
    const updatedOptions = [...updatedFormElements[updateIndex].options];
  
    // Validate options before updating
    if (validateOptions(updatedOptions)) {
      updatedFormElements[updateIndex] = {
        ...updatedFormElements[updateIndex],
        options: updatedOptions,
      };
      setFormElements(updatedFormElements);
      setEditModal(false);
      setErrorMessage("")
    } else {
      setErrorMessage("key and label can not be empty.");
    }
  };

  const addInputField = () => {
    setUpdatedImageIndexList((prevState) => {
      const filledIndexList = [...prevState, undefined];
      return filledIndexList;
    });

  }

  const removeInputField = (removeIndex) => {
    const updatedImageIndexLists = [...updatedImageIndexList];
    if(updatedImageIndexLists.length > 0) {
    updatedImageIndexLists.splice(removeIndex, 1);
    setUpdatedImageIndexList(updatedImageIndexLists);
    }
    const updatedFormElements = [...formElements];
    const samples = updatedFormElements[updateIndex].samples;
  
    if (samples.length > 1) {
      updatedFormElements[updateIndex].samples = samples.filter((_, index) => index !== removeIndex);
      setFormElements(updatedFormElements); 
    }
  };

  const handleImageChange = (event, index) => {
    const files = event.target.files;
    const updatedImageIndexLists = [...updatedImageIndexList];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      updatedImageIndexLists.map((_, innerIndex) => {
        if (innerIndex === index) {
          updatedImageIndexLists[innerIndex] = file;
        } 
      });
    }
    setUpdatedImageIndexList(updatedImageIndexLists)
  };
  
  const handleImageSubmit =  async() => {
    const formData = new FormData();

    updatedImageIndexList.map((image, i) => {
      formData.append(`file${i}`, image);
    })

    formData.append("stepName", projectSteps.stepName);
    formData.append("featureName", featureName);
        try {
            const response = await applicationsService.uploadFiles(formData, dispatch);
            if (response) {
              if(response.data.samplefilePaths.length > 0) {
                const updatedFormElements = [...formElements];
                const updatedSampleImage = [...updatedFormElements[updateIndex].samples];

                let startIndex = 0; 

                updatedImageIndexList.filter((data, index) => {
                  if (data !== undefined) {
                    updatedSampleImage[index] = {
                      ...updatedSampleImage[index],
                      name: response.data.samplefilePaths[startIndex].name,
                      type: response.data.samplefilePaths[startIndex].type,
                      path: response.data.samplefilePaths[startIndex].path,
                    };
                    startIndex++; 
                  }
                });
                
                updatedFormElements[updateIndex] = {
                  ...updatedFormElements[updateIndex],
                  samples: updatedSampleImage,
                };
                setFormElements(updatedFormElements);
              }
              else if(response && response.data.samplefilePaths.length === 0) {
                const updatedFormElements = [...formElements];
                const updatedSampleImage = Array(updatedFormElements[updateIndex].samples.length).fill({
                  name: "",
                  type: "",
                  path: "",
                });

                updatedFormElements[updateIndex] = {
                  ...updatedFormElements[updateIndex],
                  samples: updatedSampleImage,
                };

                setFormElements(updatedFormElements);

                  
              }
            } else if (response && response.Success === false) {
              console.error(response);
            }
        } 
        catch (error) {
        console.error("error", error);
        }
        setUploadModal(false)
    }

  const handleApplication = () => {
      navigate(`/controlpanel/application/${featureName}/${featureId}`);
  }

  return (
    <div>
      <Sidebar />
      <div className="mainbox">
        <div className="subapp_container">
          <h1 style={{cursor:"pointer"}}><BsFillArrowLeftCircleFill onClick={handleApplication} />  {stepId === "new" ? "Add SubApp" : "Edit SubApp"}</h1>
        </div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="row">
                <div className="form-group">
                  <label htmlFor="">
                  SubApp Name <span className="error_message"> *</span>
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    name="stepName"
                    placeholder="SubApp Name"
                    readOnly={stepId !== "new"}
                  />
                  <ErrorMessage
                    name="stepName"
                    component="div"
                    className="error_message"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">
                  SubApp Slug <span className="error_message"> *</span>
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    name="stepSlug"
                    placeholder="SubApp Slug"
                  />
                  <ErrorMessage
                    name="stepSlug"
                    component="div"
                    className="error_message"
                  />
                </div>

              </div>
              <div className="row">
                
              <div className="form-group">
                  <label htmlFor="">SubApp Send Text</label>
                  <Field
                    type="text"
                    className="form-control"
                    name="stepSendText"
                    placeholder="SubApp Send Text"
                  />
              </div>
              <div className="form-group">
                  <label htmlFor="">Api Path</label>
                  <Field
                    type="text"
                    className="form-control"
                    name="stepApiPath"
                    placeholder="Name"
                  />
                </div>
                {/* <div className="form-group">
                  <label htmlFor="">SubApp Text</label>
                  <Field
                    type="text"
                    className="form-control"
                    name="stepText"
                    placeholder="SubApp Text"
                  />
                </div> */}
              </div>
              <div className="row">
                {/* <div className="form-group">
                  <label htmlFor="">User Uploads</label>
                  <Field
                    as="select"
                    className="form-control"
                    name="stepUseruploads"
                    placeholder="User Uploads"
                  >
                    <option value="">Select</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Field>
                  <ErrorMessage
                    name="stepUseruploads"
                    component="div"
                    className="error_message"
                  />
                </div> */}

              </div>
              <div>
                <div className="row">
                  <div className="row tableBox N">
                    <table>
                      <thead>
                        <tr>
                          <th width="13%">Key</th>
                          <th width="47%">Label</th>
                          <th width="11%">Optional</th>
                          <th width="11%">Type</th>
                          <th width="29%">&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formElements.length > 0 &&
                          formElements.map((element, index) => (
                            
                            <tr key={index} className="add_step_api">
                              <td>
                                <Field
                                  type="text"
                                  className="form-control input-control"
                                  name={`serialNumber-${element}`}
                                  value={element?.key}
                                  placeholder="key1"
                                  onChange={(e) => {
                                    setKeyValue("key", e.target.value, index);
                                  }}
                                />
                              </td>
                              <td>
                                <Field
                                  type="text"
                                  className="form-control input-control"
                                  name={`apiFieldname-${element}`}
                                  value={element?.label}
                                  placeholder="label1"
                                  onChange={(e) => {
                                    setKeyValue("label", e.target.value, index);
                                  }}
                                />
                              </td>
                              <td>
                                <Field
                                  as="select"
                                  className="form-control input-control"
                                  name={`optional-${element}`}
                                  value={element?.optional || "false"}
                                  onChange={(e) => {
                                    setKeyValue(
                                      "optional",
                                      e.target.value,
                                      index
                                    );
                                  }}
                                >
                                  <option value="false">False</option>
                                  <option value="true">True</option>
                                </Field>
                              </td>
                              <td>
                                <Field
                                  className="form-control input-control"
                                  as="select"
                                  name={`type-${element}`}
                                  value={element?.type || "Text Box"}
                                  onChange={(e) => {
                                    setKeyValue("type", e.target.value, index);
                                  }}
                                >
                                  <option value="text box">Text Box</option>
                                  <option value="textarea">Textarea</option>
                                  <option value="uploadfile">
                                    Upload File
                                  </option>
                                  <option value="selectbox">Select Box</option>
                                  <option value="radio">Radio Button</option>
                                  <option value="webcamVideo">Webcam Video</option>
                                  <option value="webcamImage">Webcam Image</option>
                                  <option value="microphone">Microphone</option>
                                </Field>
                              </td>
                              <td>
                                <img
                                  src={deleteIcon}
                                  alt="Delete"
                                  className="deleteIcon"
                                  onClick={(e) => {
                                    handleDeleteFieldPopup(index);
                                  }}
                                />
                              </td>
                              {/* Edit  */}
                            {
                            (element.type === "uploadfile" || element.type === "selectbox" || element.type ==="radio") &&
                              <td>
                                <img src={editIcon} alt="add" className="addIcon" onClick={ () => handleEdit(element, index)} />
                             </td>
                              
                            }
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="form-group txt">
                    <button
                    className="form_btn"
                      type="button"
                      onClick={() => {
                        handleAddPath();
                      }}
                    >
                      + New Field
                    </button>
                  </div>
              </div>
              <div className="form-group submit">
                  <button className="form_btn" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Saving..." : "Save"}
                  </button>
                </div>
            </Form>
          )}
        </Formik>
      </div>

      {isDeletePopup ? (
        <ConfirmBox
          handleDeletePopup={handleDeleteStepPopup}
          handleDeleteData={handleDeleteStepAPI}
        />
      ) : (
        <></>
      )}
      {isFieldDeletePopup ? (
        <ConfirmBox
          handleDeletePopup={handleDeleteFieldPopup}
          handleDeleteData={handleRemoveField}
          featureId={deletedFieldIndex}
        />
      ) : (
        <></>
      )}

      {editModal && (
        <EditModal
          editModal={editModal}
          formElements={formElements[updateIndex]}
          closeModal={closeModal}
          handleInputChange={handleInputChange}
          handleAddInput={handleAddInput}
          handleDelete={handleDelete}
          error={errorMessage}
          handleSubmit={handleSubmit}
        />
      )}

      {uploadModal && (
        <EditModal
          uploadModal={uploadModal}
          closeModal={closeModal}
          formElements={formElements[updateIndex]}
          handleImageChange={handleImageChange}
          handleImageSubmit={handleImageSubmit}
          addInputField={addInputField}
          updatedImageIndexList={updatedImageIndexList}
          removeInputField={removeInputField}
        />
      )}
    </div>
  );
};

export default Step;

import React, { Component, useEffect } from 'react'
import Nav from '../Components/NavBar/Nav'
import Banner from '../Components/Banner/Banner'
import FaceRecog from '../Components/FaceRecognition/FaceRecog'
import BusinessValue from '../Components/BusinessValue/BusinessValue'
import Forms from '../Components/FormContainer/Forms'
import ProjectsList from '../Components/ProjectContainer/ProjectsList'
import contactFormImg from "../images/contact_img.png";
import Footer from '../Components/Footer/Footer'
import '../scss/helpers/_media.scss'
import './Home.scss'
import '../scss/helpers/_global.scss'
import '../scss/style.scss'
import { useDispatch, useSelector } from "react-redux";
import { getAllProjects } from "../slices/projects"
import ServicesSolution from '../Components/ServicesSolution'


const Home = () => {
  const { projectList } = useSelector((state) => state.projects);
  const { loading } = useSelector((state) => state.global);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProjects());
  }, [dispatch]);
  
    return (
        <div>
        <Nav />
        <Banner />
        <BusinessValue />
        <ServicesSolution />
        <ProjectsList projectList={projectList.features} loading={loading} />
        <div className="contact_form">
          <div className="container">
        <h4 className="heading_style wow fadeInUp">
          Let's Connect for <span>free consultation</span>
        </h4>
        <p className="wow fadeInUp">
          So Let us Connect and Transform your idea Into an awesome Solution. Go
          ahead and fill this form.
        </p>
        <div className="flexDirection">
        <div className="form_section">
        <Forms />
        </div>
        <div className="img_right wow slideInRight">
            <img src={contactFormImg} alt="" />
          </div>
        </div>

          </div>
        </div>
        <Footer/>
        </div>
    )
}

export default Home
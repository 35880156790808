import React from "react";

export default function ProjectServiceDiv(props) {
  return (
    <li key={props.index} className="wow slideInRight">
      <figure>
        <img src={props.Image} alt="" />
      </figure>
      <p>{props.description}</p>
    </li>
  );
}

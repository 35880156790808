import React, { Component } from 'react'
import "./Nav.scss"
import logo from '../../images/logo.png'
import navicon from '../../images/feather-menu.svg'
import { Link } from "react-router-dom";
import LogoSvg from "../../images/logo.svg"
class Nav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
    }
  }

  scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

  clickMenu = () => {
    this.setState((prevState) => ({
      menu: !prevState.menu
    }));
  }


  render() {
    return (<header>
      <div className="container">
        <div className="logo" ><a href={process.env.REACT_APP_TFTAI_HOME} target='_blank'><img src={LogoSvg} alt="TFT.ai" /></a></div>
        <nav>
          <div onClick={ this.clickMenu} className="fa fa-bars"><img src={navicon} alt="nav" /></div>
          <ul style={{display : this.state.menu ? "inline" : ""}} >
            {/* <li><Link to='/solutions'>AI Solutions</Link></li> */}
            {/* <li><Link to='/contact'>Contact Us</Link></li> */}
          </ul>
        </nav>
      </div>
    </header>
    )
  }
}

export default Nav
import React, { Component } from 'react'
import ServiceList from '../OurServices/ServiceList/ServiceList'
import ServiceWorks from '../OurServices/ServiceWorks/ServiceWorks'
import Nav from '../Components/NavBar/Nav'
import CommonBanner from '../Components/CommonBanner/CommonBanner'
import Forms from '../Components/FormContainer/Forms'
import Footer from '../Components/Footer/Footer'
import contactFormImg from "../images/contact_img.png";
export default class OurServices extends Component {
  render() {
    return (
      <div>
        <Nav />
        <CommonBanner heading1="Oue" heading2="Services" description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since" componentdirection="Services" />
        <ServiceList />
        <ServiceWorks />
        <div className="contact_form">
      <div className="container">
        <h4 className="heading_style wow fadeInUp">
          Let's Connect for <span>free consultation</span>
        </h4>
        <p className="wow fadeInUp">
          So Let us Connect and Transform your idea Into an awesome Solution. Go
          ahead and fill this form.
        </p>

        <div className="flexDirection">
        <div className="form_section">
        <Forms />
        </div>
        <div className="img_right wow slideInRight">
            <img src={contactFormImg} alt="" />
          </div>
        </div>

      </div>
    </div>
           <Footer />
      </div>
    )
  }
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setLoggedIn(state, action) {
      state.isLoggedIn = action.payload;
    },
    setUserRole(state, action) {
      state.userRole = action.payload
    }
  }
});

export const setLoading = (loading) => {
  return (dispatch) => {
    dispatch(slice.actions.setLoading(loading));
  };
};

export const setLoggedIn = (loggedIn) => {
  return (dispatch) => {
    dispatch(slice.actions.setLoggedIn(loggedIn));
  };
};

export const setUserRole = (role) => {
  return (dispatch) => {
    dispatch(slice.actions.setUserRole(role))
  };
}

export const { reducer } = slice;
import React from 'react';
import "../../../Admin/styles.scss";
import closeIcon from "../../../../images/adminimages/ionic-md-close.png";

export default function EditModal({
  editModal,
  uploadModal,
  closeModal,
  formElements,
  handleInputChange,
  handleAddInput,
  error,
  handleDelete,
  handleSubmit,
  handleImageChange,
  handleImageSubmit,
  addInputField,
  updatedImageIndexList,
  removeInputField,
}) {
  const renderInputFields = () => {
    const inputFields = [];

    for (let i = 0; i < updatedImageIndexList.length; i++) {
      inputFields.push(
        <div key={i} className='buttonContainer'>
          <input
            type="file"
            // accept="image/*"
            onChange={(e) => handleImageChange(e, i)}
          />
          <h3> <img src={closeIcon} alt="close" onClick={() => removeInputField(i)} /> </h3>
        </div>
      );
    }

    return inputFields;
  };

  return (
    <div className="popupoverlay">
      <div className="popupbox radioBox">
        {editModal && (
          <div>
            <h3>Options
              <img src={closeIcon} alt="close" onClick={() => closeModal()} />
            </h3>
            <p className="error">{error}</p>
            {formElements.options.map((inputField, index) => (
              <div className="radioInputContainer" key={index}>
                <input
                  type="text"
                  className="form-control"
                  placeholder={`Key${index + 1}`}
                  value={inputField.value}
                  onChange={(event) => handleInputChange(index, event, "value")}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder={`Label${index + 1}`}
                  value={inputField.label}
                  onChange={(event) => handleInputChange(index, event, "label")}
                />
                <h3> <img src={closeIcon} alt="close" onClick={() => handleDelete(index)} /> </h3>
              </div>
            ))}
            <div className="buttonContainer">
              <button type="button" onClick={() => { handleAddInput() }}> + </button>
              <button type="button" onClick={() => handleSubmit()}>
                Submit
              </button>
            </div>
          </div>
        )}
        {uploadModal && (
          <div>
            <h3>Image
              <img src={closeIcon} alt="close" onClick={() => closeModal()} />
            </h3>
            {renderInputFields()}
            {formElements.samples.length > 0 && formElements.samples.map((data, i) => (
              data.name !== "" && (
                <div key={i}>
                  <img src={data.path} alt={`Preview Image`} className='iconImage' />
                </div>
              )
            ))}
            <div className="buttonContainer">
              <button type="button" onClick={() => { addInputField() }}> + </button>
              <button type="button" onClick={() => handleImageSubmit()}>Submit</button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

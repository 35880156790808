import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import './App.css';
import About from "./Pages/About";
import OurServices from './Pages/OurServices'
import ProjectPage from "./Pages/ProjectPage/ProjectPage";
import Contact from './Pages/Contact'
import { ScrollToTop } from "./utils/useScrollTop";
import { Provider as ReduxProvider } from 'react-redux';
import { store }  from "./store";
import ProjectDetails from "./Components/ProjectDetails/ProjectDetails";
import TransparentLoader from "./Components/Loader/TransparentLoader";
import { ToastContainer } from "react-toastify";
import Admin from "./Components/Admin";
import Dashboard from "./Components/Admin/Dashboard/Dashboard";
import Users from "./Components/Admin/Users/Users"
import Enquiries from "./Components/Admin/Enquiries/Enquiries";
import ChangePassword from "./Components/Admin/AccountSettings/ChangePassword";
import Applications from "./Components/Admin/Applications/Applications";
import EditApplication from "./Components/Admin/Applications/EditApplication";
import CreateApplication from "./Components/Admin/Applications/CreateApplication";
import Step from "./Components/Admin/Applications/Step/Step";
import Version from "./Components/Version";
import NotFound from "./Components/Notfound/Notfound";
import HomePageNew from "./Pages/HomePageNew";

const App = () => {
  return (
    <div className="App">
      <ReduxProvider store={store}>
        <TransparentLoader>
          <ToastContainer/>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
               {/* <Route path='/' element={<Home />} />  */}  { /*commenting home page as of now */ }
              <Route path='/' element={<ProjectPage />} />
              <Route path='/about' element={<About />} />
              <Route path='/services' element={<OurServices />} />
              <Route path='/solutions' element={<ProjectPage />} />
              {/* <Route path='/' element={<ProjectPage />} /> */}
              <Route path='/contact' element={<Contact />} />
              <Route path='/solutions/:projectName/:projectId' element={<ProjectDetails />} />
              {/* Admin Routes */}
              <Route path='/controlpanel' element={<Admin />} />
              <Route path='/controlpanel/dashboard' element={<Dashboard />} />
              <Route path='/controlpanel/applications' element={<Applications />} />
              <Route path='/controlpanel/applications/new' element={<CreateApplication />} />
              <Route path='/controlpanel/application/:featureName/:featureId' element={<EditApplication />} />
              <Route path='/controlpanel/application/:featureName/:featureId/step/:stepId' element={<Step />} />
              <Route path='/controlpanel/users' element={<Users />} />
              <Route path='/controlpanel/enquiries' element={<Enquiries />} />
              <Route path='/controlpanel/change-password' element={<ChangePassword />} />
              {/* healthcheck Route */}
              <Route path = "/healthcheck" element = {<Version />} />

              <Route path="/*" element={<NotFound />} />

              <Route path='/front/' element={<Contact />} />
              
              {/* <Route path='/' element={<HomePageNew />} /> */}
            </Routes>
          </BrowserRouter>
        </TransparentLoader>
      </ReduxProvider>
    </div>
  );
}

export default App;

import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import ProjectService from '../services/projectService';
import { setLoading } from './global';

const projectService = new ProjectService();

const initialState = {
  projectList: [],
  projectDetails: {},
  projectSteps: {},
  demoResponse: {}
};

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setAllProjects(state, action) {
      state.projectList = action.payload;
    },
    setPublishedProjects(state, action) {
      state.projectList = action.payload;
    },
    setDetails(state, action) {
      state.projectDetails = action.payload;
    },
    setSteps(state, action) {
      state.projectSteps = action.payload;
    },
    setDemoResponse(state, action) {
      state.demoResponse = action.payload;
    }
  }
});

export const getAllProjects = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await projectService.getAllProjects();
      if (response.Success) {
        dispatch(slice.actions.setAllProjects(response.data));
      }
    } catch (error) {

    } finally {
      dispatch(setLoading(false));
    }
  };
};
export const getPublishedProjects  = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await projectService.getPublishedProjects ();
      if (response.Success) {
        dispatch(slice.actions.setPublishedProjects(response.data));
      }
    } catch (error) {

    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getProjectDetails = (projectId) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await projectService.getProjectDetails(projectId);
      if (response) {
        dispatch(slice.actions.setDetails(response.data));
      }
    } catch (error) {

    } finally {
      dispatch(setLoading(false));
    }
  };
}

export const getProjectSteps = (projectId, stepId) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      if (stepId && projectId) {
        const response = await projectService.getProjectSteps(projectId, stepId);
        if (response) {
          dispatch(slice.actions.setSteps(response));
        }
      }
    } catch (error) {

    } finally {
      dispatch(setLoading(false));
    }
  };
};

  export const sendFormData = (projectId, step, data) => {
     const formData = new FormData();
     formData.append('feature_id', projectId);
     formData.append('step_id', step);
     for (const [key, value] of Object.entries(data)) {
     if (key.includes('file') || key.includes('image') || key.includes('video')) {
      if(value.length === 1) {
        formData.append(key, value[0]);
      }
      else {
        formData.append(key, value);
      }
     } else {
     formData.append(key, value);
     }
     }
  return async (dispatch) => {
    try {
     dispatch(setLoading(true));
     if (projectId && step && data) {
     const response = await projectService.submitFormData(formData);
     // if (response.status_code === "200") {
     // toast.success(response.status_description)
     if(response)
     // toast.warn(response.message)
     dispatch(slice.actions.setDemoResponse(response));
     // }
     }
     } catch (error) {
      dispatch(slice.actions.setDemoResponse(error.response.data));
      toast.error(error)
     } finally {
     dispatch(setLoading(false));
     }
     };
};

export const { reducer } = slice;

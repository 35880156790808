import React, { useEffect,useState} from 'react';
import { Sidebar } from '../Sidebar/Sidebar';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TotalApplications from '../../../images/adminimages/total-app.svg';
import TotalSteps from '../../../images/adminimages/step.svg';
import TotalHits from '../../../images/adminimages/total-hit.svg';
import TotalEnquiries from '../../../images/adminimages/total-inquries.svg';
import { getDashboardStats } from '../../../slices/admin';
import DashboardBarChart from './DashboardBarChart';
import bulleye from "../../../images/adminimages/bullseye-solid.svg";


const Dashboard = () => {
	const { isLoggedIn } = useSelector((state) => state.global);
	const { dashboardStats } = useSelector((state) => state.admin);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!isLoggedIn) {
			navigate("/controlpanel");
		}
		dispatch(getDashboardStats())
		.then()
	}, [navigate]);
	useEffect(() => {
		if (!isLoggedIn) {
		  navigate("/controlpanel");
		}
		dispatch(getDashboardStats())
		  .then(() => {
			setLoading(false);
		  })
		  .catch((error) => {
			console.error("Error loading dashboard stats: ", error);
			setLoading(false);
		  });
	  }, [navigate]);

	const xAxisData = dashboardStats?.stepHits[0].stepHits.map((data) => data.label );
	const yAxisData = dashboardStats?.stepHits[0].stepHits.map((data) => data.value );
	return (
		<div>
		  <Sidebar />
		  {loading ? (
          // Render a loading indicator when data is loading
          <div className="loading-indicator">Loading...</div>
        ) : (
		  <div className="mainbox">
			<h1>Dashboard</h1>
			<div className="row">
				{/* commenting for now subapp count for now  */}
			  {/* {dashboardStats &&
				dashboardStats.dashboardStats.map((item, index) => (
				  <div className="box1" key={index}>
					<p>
					  {item.value} <span>{item.label}</span>
					</p>
					<figure>
					  <img src={getImagePath(item.label)} alt={item.label} />
					</figure>
				  </div>
				))} */}
				{dashboardStats &&
					dashboardStats.dashboardStats
						.filter((item) => item.label !== "Total SubApps")
						.map((item, index) => (
						<div className="box1" key={index}>
							{console.log(item, "item")}
							<p>
							{item.value} <span>{item.label}</span>
							</p>
							<figure>
							<img src={getImagePath(item.label)} alt={item.label} />
							</figure>
						</div>
				))}
			  <div style={{ maxWidth: "100%", overflowX: "auto" }}>
				<DashboardBarChart xAxisData={xAxisData} yAxisData={yAxisData} />
			  </div>
			</div>
		  </div>
		)}
		</div>
	  );
};

// Map the label to the corresponding image path
const getImagePath = (label) => {
	switch (label) {
		case "Total Applications":
			return TotalApplications;
		case "Total SubApps":
			return TotalSteps;
		case "Total Hits (last 30 days)":
			return bulleye;
		case "Total Enquiries (last 30 days)":
			return TotalEnquiries;
		default:
			return "";
	}
};

export default Dashboard;

import * as React from "react";
import { useRef, useEffect } from "react";
import * as echarts from "echarts/core";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent
} from "echarts/components";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { LegendComponent } from "echarts/components";

echarts.use([
  BarChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LabelLayout,
  UniversalTransition,
  LegendComponent,
  CanvasRenderer
]);

function ChartComponent({ xAxisData, yAxisData }) {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const myChart = echarts.init(chartRef.current);
      myChart.setOption({
        xAxis: {
          type: "category",
          data: xAxisData,
          axisLabel: {
            interval: 0, // Prevent label overlap
            rotate: 30, // Rotate labels for better readability
            paddingBottom: 4,
          },
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            data: yAxisData,
            type: "bar",
          }
        ]
      });
    }
  }, [xAxisData, yAxisData]);

  return <div id="myChart" ref={chartRef} className="barChart" ></div>;
}

export default ChartComponent;
import React from "react";
import ProjectServiceDiv from "./ProjectServiceDiv";
import faceNumber from "../../images/unlimited-number-of-faces.png";
import adaptToScenes from "../../images/adapt-to-scenes.png";
import InternationalAwards from "../../images/international-awards.png";
import "./ProjectService.scss";

export default function ProjectServices() {
  const listOfServices = [
    {
      image: faceNumber,
      contect: "Unlimited Number of Faces",
    },
    {
      image: adaptToScenes,
      contect: "Adapt to Various Scenes",
    },
    {
      image: adaptToScenes,
      contect: "Fast and Accurate",
    },
    {
      image: InternationalAwards,
      contect: "Intenational Awards",
    },
  ];
  return (
    <div>
      <div className="services_section row">
        <div className="container">
          <ul>
            {listOfServices.map((item, index) => {
              return (
                <ProjectServiceDiv
                  index={index}
                  Image={item.image}
                  description={item.contect}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

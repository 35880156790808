// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/contact_bg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact_form {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  min-height: 500px;
  clear: both;
  background-size: cover;
  margin-bottom: 40px;
}
.contact_form h4 {
  text-align: left;
  margin-bottom: 0;
}
.contact_form .form_section {
  width: 620px;
  display: inline-block;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  padding: 25px;
  margin-top: 25px;
  background: #fff;
}
.contact_form .form_section .row .form_group .error_message {
  color: red;
}
.contact_form .form_section .row .form_group .no_arrows::-webkit-inner-spin-button,
.contact_form .form_section .row .form_group .no_arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.contact_form .form_section .row .form_group .no_arrows {
  -moz-appearance: textfield;
}
.contact_form .form_section .row .form_group .countryCode {
  width: 6rem;
}
.contact_form .form_section textarea {
  height: 100px;
  padding-top: 20px;
}
.contact_form .img_right {
  width: 430px;
  display: inline-block;
  margin-left: 100px;
}
.contact_form .flexDirection {
  display: flex;
}

.textMessage {
  height: 100px !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/FormContainer/Forms.scss"],"names":[],"mappings":"AAAA;EACE,mDAAA;EACA,iBAAA;EACA,WAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;EACA,gBAAA;AACJ;AAEE;EACE,YAAA;EACA,qBAAA;EACA,4CAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;AAAJ;AAGQ;EACE,UAAA;AADV;AAGQ;;EAEE,wBAAA;EACA,SAAA;AADV;AAGQ;EACE,0BAAA;AADV;AAGQ;EACE,WAAA;AADV;AAMI;EACE,aAAA;EACA,iBAAA;AAJN;AAQE;EACE,YAAA;EACA,qBAAA;EACA,kBAAA;AANJ;AASE;EACE,aAAA;AAPJ;;AAWA;EACE,wBAAA;AARF","sourcesContent":[".contact_form {\n  background: url(\"../../images/contact_bg.svg\");\n  min-height: 500px;\n  clear: both;\n  background-size: cover;\n  margin-bottom: 40px;\n\n  h4 {\n    text-align: left;\n    margin-bottom: 0;\n  }\n\n  .form_section {\n    width: 620px;\n    display: inline-block;\n    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);\n    border-radius: 15px;\n    padding: 25px;\n    margin-top: 25px;\n    background: #fff;\n    .row {\n      .form_group {\n        .error_message {\n          color: red;\n        }\n        .no_arrows::-webkit-inner-spin-button,\n        .no_arrows::-webkit-outer-spin-button {\n          -webkit-appearance: none;\n          margin: 0;\n        }\n        .no_arrows {\n          -moz-appearance: textfield;\n        }\n        .countryCode {\n          width: 6rem;\n        }\n      }\n    }\n\n    textarea {\n      height: 100px;\n      padding-top: 20px;\n    }\n  }\n\n  .img_right {\n    width: 430px;\n    display: inline-block;\n    margin-left: 100px;\n  }\n\n  .flexDirection {\n    display: flex;\n  }\n}\n\n.textMessage {\n  height: 100px !important; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

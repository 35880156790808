import React, { useEffect, useState } from "react";
import { Sidebar } from "../Sidebar/Sidebar";
import eyeOff from '../../../images/adminimages/eye-off.png';
import eyeOn from '../../../images/adminimages/visible.png';
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import AuthService from "../../../services/AuthService";

const ChangePassword = () => {
  const authService = new AuthService();
  const dispatch = useDispatch();

  const [passwordFieldType, setPasswordFieldType] = useState({
    oldPassword: "password",
    newPassword: "password",
    confirmPassword: "password"
  });

  const initialValues = {
    oldpassword: "",
    newpassword: "",
    confirmpassword: ""
  };

  const validationSchema = Yup.object().shape({
    oldpassword: Yup.string().required("Old password is required"),
    newpassword: Yup.string().required("New password is required"),
    confirmpassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("newpassword")], " Confirm Password and New pasword must match"),
  });


  const handleSubmit = async (values) => {
    const oldpassword = values.oldpassword;
    const newpassword = values.newpassword;
    const payload = {
      'old_password': oldpassword,
      'new_password': newpassword,
    }
    await authService.changePassword(payload, dispatch)
  }

  const togglePasswordVisibility = (fieldName) => {
    setPasswordFieldType((prevType) => {
      const updatedType = { ...prevType };
      if (prevType[fieldName] === "password") {
        updatedType[fieldName] = "text";
        setEyeIcon(eyeOn);
      } else {
        updatedType[fieldName] = "password";
        setEyeIcon(eyeOff);
      }
      return updatedType;
    });
  };

  const [eyeIcon, setEyeIcon] = useState(eyeOff);
  return (
    <div>
      <Sidebar />
      <div className="mainbox">
        <h1>Change Password</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="changepass">
              <div className="form-group">
                <label>Old Password</label>
                <span>  <img src={passwordFieldType.oldPassword === "password" ? eyeOff : eyeOn} alt="Toggle Password Visibility" onClick={() => togglePasswordVisibility('oldPassword')} /></span>
                <Field type={passwordFieldType.oldPassword} className="form-control" name="oldpassword" placeholder="Old Password" autoComplete="false" />
                <ErrorMessage
                  name="oldpassowrd"
                  component="div"
                  className="error_message"
                />
              </div>
              <div className="form-group">
                <label>New Password</label>
                <span><img src={passwordFieldType.newPassword === "password" ? eyeOff : eyeOn} alt="Toggle Password Visibility" onClick={() => togglePasswordVisibility('newPassword')} /></span>
                <Field type={passwordFieldType.newPassword} className="form-control" name="newpassword" placeholder="New Password"   autoComplete="false"/>
                <ErrorMessage
                  name="newpassword"
                  component="div"
                  className="error_message"
                />
              </div>
              <div className="form-group">
                <label>Confirm Password</label>
                <span> <img src={passwordFieldType.confirmPassword === "password" ? eyeOff : eyeOn} alt="Toggle Password Visibility" onClick={() => togglePasswordVisibility('confirmPassword')} /></span>
                <Field type={passwordFieldType.confirmPassword} className="form-control" name="confirmpassword" placeholder="Confirm Password"  autoComplete="false" />
                <ErrorMessage
                  name="confirmpassword"
                  component="div"
                  className="error_message"
                />
              </div>
              <div className="form-group">
                <button type="submit" className="form_btn">{isSubmitting ? "Submitting..." : "Submit"}</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangePassword;

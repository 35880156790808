import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { useSelector as useReduxSelector } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from './rootReducer';


const persistConfig = {
  key: 'my-store',
  storage,
  whitelist: ['global']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === "true"
});

const persistedStore = persistStore(store);

setupListeners(persistedStore.dispatch);

export const RootState = persistedStore.getState();
export const useSelector = useReduxSelector;
export const AppDispatch = persistedStore.dispatch;

export const AppThunk = (action) => (dispatch, getState) => {
  if (typeof action === 'function') {
    return action(dispatch, getState);
  }
  dispatch(action);
};
import React from 'react';
import Slider from 'react-slick';
import "../../scss/styleNew.scss";
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
const Testimonial = () => {

	// this object is use in Slider to modify the speed and autoplay
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
	};

	return (

		<div >
			<div>
				<div className="container_testimonial">
					<Slider {...settings}>
                        <div className="item quote">
                            <h5><i>Director of a Pharmaceutical Company</i></h5>
                            <p>
                            TFT.AI’s Action Recognition Solutions have been completely
                            integrated into our labs across various locations. This has
                            enabled us to help our employees follow strict Dos and Dont’s
                            practices. The fact that TFT.AI already had a working PoC and
                            could quickly finetune it with our data was a standout
                            accomplishment.
                            </p>
                        </div>
                        <div className="item quote">
                        <h5><i>IT Manager of a Hospital in Dubai</i></h5>
                        <p>We wanted our patients to have a great experience while being in our hospitals. TFT.AI has provided a Speech to Text solution that cuts the waiting time of the patients close to zero while waiting for a prescription post their meeting with the doctor. TFT.AI has also ensured that not only the solution is HIPAA compliant but also the data remains with us by commissioning their ML Models to work on-premise.</p>
                        </div>
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default Testimonial;

import React, { Component } from 'react'
import {TfiEmail} from "react-icons/tfi";
import {FiPhoneCall} from "react-icons/fi";
import { Link } from 'react-router-dom'

class Footer extends Component {
    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    render() {
        return (
            <footer className="row">
                <div className='footerContainer'>
                    <h3>Contact Us</h3>
                    <p> <TfiEmail />  info@tft.ai</p>
                    {/* <p style={{marginLeft:"26px"}}> <FiPhoneCall /> +31633292525</p> */}
                </div>
                <div className="copyfooter">
                    Copyright &copy; 2024 TFTAI Systems Pvt Ltd. All Rights Reserved
                </div>
            </footer>
        )
    }
}

export default Footer
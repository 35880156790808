import React, { Component } from 'react'
import ConnectionStep from './ConnectionStep'
import workingStepBg from '../../images/how_it_work.png'

export default class ServiceWorks extends Component {
    render() {
        return (
            <div>
                <div className="how_it_work row">
                    <div className="container">
                        <div className="col-7">

                            <figure className="wow slideInLeft">
                                <img src={workingStepBg} alt="" />
                            </figure>
                        </div>
                        <div className="col-5">
                            <div className="services_list">
                                <ul>
                                    <ConnectionStep stepNumber="1" stepName="Register / Login To Our Platform" stepDescription="Lorem ipsum dolor sit amet elit sed. consectetur sedo adipi amet lio. Lorem ipsum dolor sit amet elit sed. consectetur sedo adipi amet lio." />
                                    <ConnectionStep stepNumber="2" stepName="Enter Your Information Details" stepDescription="Lorem ipsum dolor sit amet elit sed. consectetur sedo adipi amet lio. Lorem ipsum dolor sit amet elit sed. consectetur sedo adipi amet lio." />
                                    <ConnectionStep stepNumber="3" stepName="Follow Your Software Usage Steps" stepDescription="Lorem ipsum dolor sit amet elit sed. consectetur sedo adipi amet lio. Lorem ipsum dolor sit amet elit sed. consectetur sedo adipi amet lio." />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import React from "react";
import contactFormImg from "../../images/contact_img.png";
import Forms from "../FormContainer/Forms";
import "../CommonBanner/CommonBanner.scss";

export default function ConnectForm() {
  return (
    <div>
      <div className="contact_page row">
        <div className="container">
          <h4 className="heading_style fs-26 mB0 t_center">
          Let's Connect for <span>free consultation</span>
            {/* Drop us Message for any <span>Query</span> */}
          </h4>
          <p className="t_center">
            So let us Connect and Transform your idea into an awesome solution.
            Go ahead and fill this form.
          </p>
          <div className="containerFlex">
          <div className="col-6">
            <div className="form_section">
            <Forms />
            </div>            
            </div>
            <div className="col-6">
              <div className="contact-image">
                <img src={contactFormImg} alt="" />
              </div>
            </div>
           </div>
        </div>
      </div>
    </div>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services_section .container ul {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 540px) {
  .services_section .container ul {
    display: block;
    width: 100%;
  }
  .services_section .container ul li {
    margin-bottom: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/ProjectDetails/ProjectService.scss"],"names":[],"mappings":"AAEQ;EACI,aAAA;EACA,8BAAA;EACA,WAAA;AADZ;;AAMA;EAGY;IACI,cAAA;IACC,WAAA;EALf;EAMe;IAAG,mBAAA;EAHlB;AACF","sourcesContent":[".services_section {\n    .container {\n        ul {\n            display: flex;\n            justify-content: space-between;\n            width: 100%;\n        }\n    }\n}\n\n@media only screen and (max-width: 540px) {\n    .services_section {\n        .container {\n            ul {\n                display: block;\n                 width: 100%;\n                 li{margin-bottom: 15px;}\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

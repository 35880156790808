import React, { Component } from 'react'
import Img from '../../images/aI_development.png'
import Services from './Services'
import machineLearning from '../../images/machine_learning.png'
import questionAnswering from '../../images/question_answering.png'
import informationExtract from '../../images/information_extraction.png'
import machineTranslation from '../../images/machine_translation.png'
import semanticSearch from '../../images/semantic_serach.png'
import speechRecog from '../../images/speech_recognization.png'

export default class ServiceList extends Component {

    render() {
        this.state = {
            listOfServices: [
                {
                    serviceImg: machineLearning,
                    serviceName: "Machine Learning",
                    serviceDescription: "Lorem ipsum dolor sit amet elit sed. consectetur sedo adipi amet lio. Lorem ipsum dolor sit amet elit sed. consectetur sedo adipi amet lio."
                }
                , {
                    serviceImg: questionAnswering,
                    serviceName: "Question Answering",
                    serviceDescription: "Lorem ipsum dolor sit amet elit sed. consectetur sedo adipi amet lio. Lorem ipsum dolor sit amet elit sed. consectetur sedo adipi amet lio."
                }, {
                    serviceImg: informationExtract,
                    serviceName: "Information Extraction",
                    serviceDescription: "Lorem ipsum dolor sit amet elit sed. consectetur sedo adipi amet lio. Lorem ipsum dolor sit amet elit sed. consectetur sedo adipi amet lio."
                },
                {
                    serviceImg: machineTranslation,
                    serviceName: "Machine Translation",
                    serviceDescription: "Lorem ipsum dolor sit amet elit sed. consectetur sedo adipi amet lio. Lorem ipsum dolor sit amet elit sed. consectetur sedo adipi amet lio."
                },
                {
                    serviceImg: semanticSearch,
                    serviceName: "Semantic Search",
                    serviceDescription: "Lorem ipsum dolor sit amet elit sed. consectetur sedo adipi amet lio. Lorem ipsum dolor sit amet elit sed. consectetur sedo adipi amet lio."
                },
                {
                    serviceImg: speechRecog,
                    serviceName: "Speech Recognition",
                    serviceDescription: "Lorem ipsum dolor sit amet elit sed. consectetur sedo adipi amet lio. Lorem ipsum dolor sit amet elit sed. consectetur sedo adipi amet lio."
                }]
        }

        return (
            <div>
                <div className="our_services_section row">
                    <div className="container">

                        <div>{this.state.listOfServices.map((services, index) => {
                            return <Services key={index} serviceImg={services.serviceImg} serviceName={services.serviceName} serviceDescription={services.serviceDescription} />
                        })}</div>

                    </div>
                </div>
            </div>
        )
    }
}

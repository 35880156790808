import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './CommonBanner.scss'

class CommonBanner extends Component {
  render() {
    const { componentdirection1, heading, componentdirection2 } = this.props;

    return (
      <div className="common_banner">
        <div className="container">
          {/* Props to be used here */}
          <h3 className="heading_ocr mB0 wow slideInRight">{heading} </h3>
        </div>
        <div className="breadcrumb wow slideInLeft">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            {componentdirection1 && (
              <li>
                <Link to="/solutions">{componentdirection1}</Link>
              </li>
            )}
            <li>
              <a>{componentdirection2}</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
        
export default CommonBanner
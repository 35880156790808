import React from 'react'
import { useState, useEffect } from "react";

export default function ClientProject(props) {
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCounter((prevCounter) => {
                if (prevCounter === props.totalnumber) {
                    clearInterval(interval);
                    return prevCounter;
                }
                return prevCounter + 1;
            });
        }, 1);
        return () => {
            clearInterval(interval);
        };
    }, [props.totalNumber, props.title]);

    return (
        <div>
            <div className="col-3">
                <figure>
                    <img src={props.bgImg} alt="" />
                    <span data-purecounter-duration="0" data-purecounter-end="500" className="purecounter">{counter}</span><span>+</span>
                </figure>
                <p>{props.title}</p>
            </div>
        </div>
    )
}

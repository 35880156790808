import React, { useEffect } from "react";
import ProjectOverview from "./ProjectOverview";
import ProjectServices from "./ProjectServices";
import { useParams, useNavigate } from "react-router-dom";
import Nav from "../NavBar/Nav";
import CommonBanner from "../CommonBanner/CommonBanner";
import ConnectForm from "../Connect/ConnectForm";
import Footer from "../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getProjectDetails, getProjectSteps } from "../../slices/projects";
import Demo from "./ProjectDemo";

export const ProjectDetails = () => {
  const { projectId } = useParams();
  const { projectDetails } = useSelector((state) => state.projects);
  const { loading } = useSelector((state) => state.global);
  const defaultStep = projectDetails && projectDetails?.steps?.[0]?.stepId;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getProjectDetails(projectId));
    return () => {
      dispatch(getProjectDetails(projectId));
    };
  }, [dispatch, projectId]);

  useEffect(() => {
    defaultStep && dispatch(getProjectSteps(projectId, defaultStep));
  }, [dispatch, projectId, defaultStep]);

  useEffect(() => {
    if (projectDetails.is_published === false) {
      navigate(`/solutions`);
    }
  }, [projectDetails]);

  return (
    <div>
      <Nav />
      <CommonBanner
        heading={projectDetails.featureSlug}
        componentdirection1="AI Solutions"
        componentdirection2={projectDetails.featureSlug}
      />
      <ProjectOverview
        video={projectDetails.featureVideoPath}
        description={projectDetails.featureDescription}
      />
      <Demo loading={loading} projectId={projectId} />
      <ConnectForm />
      <Footer />
    </div>
  );
};

export default ProjectDetails;

import React, { useEffect, useState } from "react";
import { useSelector } from "../../store";
import { getProjectSteps, sendFormData } from "../../slices/projects";
import { useDispatch } from "react-redux";
import uploadImg from "../../images/upload_img.png";
import { ToastContainer,toast } from 'react-toastify';
import {BiSolidCopyAlt} from "react-icons/bi";
import {BsFillArrowDownCircleFill} from "react-icons/bs";
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from "file-saver";
import "./ProjectDemo.scss";
import WebcamVideo from "./WebCam/WebcamVideo";
import WebcamImage from "./WebCam/WebCamImage";
import AudioRecorder from "./WebCam/AudioRecorder";


const ProjectDemo = ({ loading, projectId }) => {
  const { projectDetails, projectSteps, demoResponse } = useSelector(
    (state) => state.projects
  );
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState(false);
	const [selectedSampleIndex, setSelectedSampleIndex] = useState(null);
  const [fileContent, setFileContent] = useState({});
  const [activeTab, setActiveTab] = useState({
    stepId: "",
    stepName: "",
  });
  const FileRef = {};
  const [formData, setFormData] = useState({});
  const [openDemoModal, setOpenDemoModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [sampleImage, setSampleImage] = useState([]);


  useEffect(() => {
    if (projectDetails.steps && projectDetails.steps.length > 0) {
      setActiveTab({
        stepName: projectDetails.steps[0].stepName,
        stepId: projectDetails.steps[0].stepId,
      });
    }
  }, [dispatch, projectDetails.steps]);
  const validateForm = () => {
    if (formData !== undefined) {
      const requiredFields = projectSteps.params.filter(
        (field) => field.optional === "false"
      );
      const obj2 = formData[activeTab.stepId];
      if (obj2 !== undefined) {
        const requiredKeys = requiredFields.map((field) => field.key);
        return requiredKeys.every((key) => obj2.hasOwnProperty(key));
      } else {
        return false;
      }
    }
    return false;
  };
  const getErrorMsg = (fieldId, fieldType = "") => {
    if (showError) {
      const requiredFields = projectSteps.params.filter(
        (item) => item.optional === "false"
      );
      const isRequiredField = requiredFields.find(
        (item) => item.key === fieldId
      );
      if (isRequiredField) {
        const found = formData?.[activeTab?.stepId]?.[fieldId];
        if (!found && fieldType === "") {
          return "This is a Required Field";
        }

        // if(!found && fieldType === "uploadfile"){
        //   return ""
        // }
        if(!found && fieldType === "uploadfile"){
          return "This is a Required Field"
        }
      }
      if( isRequiredField !== undefined && isRequiredField.type === "selectbox") {
        const selectFound = formData?.[activeTab?.stepId]?.[fieldId];
        if(selectFound.length === 0 ){
          return "This is a Required Field";
        }
      }
      if(isRequiredField === undefined) {
        return ""
      }
    }
    return "";
  };
  useEffect(() => {
    if (formSubmitted && demoResponse && demoResponse.Success ) {
      demoResponse?.data?.popup.forEach((item, index) => {
        if (item?.type === "text" && item?.src) {
          fetch(item.src)
            .then((response) => response.text())
            .then((data) => {
              setFileContent((prevContents) => ({
                ...prevContents,
                [index]: data,
              }));
            })
            .catch((error) => console.error("Error:", error));
        }
      });
    }
  }, [formSubmitted, demoResponse]);
  const handleSubmit = async () => {
    setShowError(true);

    if (validateForm()) {
      try {
        await dispatch(
          sendFormData(projectId, activeTab.stepId, formData[activeTab.stepId])
        );

        setFormSubmitted(true);
      } catch (error) {
        console.error("Error dispatching form data:", error);
      }
    }
  };
   const handleTabChange = (stepName, stepId) => {
    dispatch(getProjectSteps(projectId, stepId));
    setActiveTab({
      stepName,
      stepId,
    });
    setShowError(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    console.log("File dropped");
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    console.log("ok");
  };

  const updateSelect = (prevFormData, step, fieldId, value) => {
    const prevValue = prevFormData?.[step]?.[fieldId];
    if (prevValue) {
      return prevValue.includes(value)
        ? prevValue.filter((item) => item !== value)
        : [...prevFormData?.[step]?.[fieldId], value];
    } else{
      return [value]
      };
  };

  const handleInputChange = (step, fieldId, value, type) => {
    if (type === "select") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [step]: {
          ...prevFormData[step],
          [fieldId]: updateSelect(prevFormData, step, fieldId, value),
        },
      }));
    } else {
      if(value.length === 0) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [step]: {
            ...prevFormData[step],
            [fieldId]: "",
          },
        }));
        // setSampleImage(null);
      }
      else if (value.length > 0) {
        // setSampleImage(null);
        setFormData((prevFormData) => ({
          ...prevFormData,
          [step]: {
            ...prevFormData[step],
            [fieldId]: value,
          },
        }));
      }
      else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [step]: {
            ...prevFormData[step],
            [fieldId]: value,
          },
        }));
      }

    }
  };
  const copyToClipboard = () => {
    const textToCopy = demoResponse !== undefined && JSON.stringify(demoResponse.data.result, null, 2);
    if(navigator.clipboard) {
      navigator.clipboard.writeText(textToCopy).then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      }).catch(error => {
        console.error('Failed to copy: ', error);
      });
    }
    else {
      alert("Clipboard API is not supported in this browser")
    }

  };

  const renderUploadedFiles = (stepName, key) => {
    const files = formData[stepName]?.[key];
    if (files) {
      return (
        <ul>
          {Array.from(files).map((file, index) => (
            <li key={index}>{file.name}</li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const fileAccepts = (keyName) => {
    const fileType = keyName.toLowerCase();
    if (fileType.includes("image")) {
      return "image/*";
    } else if (fileType.includes("video")) {
      return "video/*";
    } else if (fileType.includes("audio")) {
      return "audio/*";
    } else return "";
  };
  const ref = React.createRef();

const handleSampleImage = (singleImage, itemType, itemKey) => {
  // setSampleImage(singleImage.path);
  setFormData((prevFormData) => ({
    ...prevFormData,
    [activeTab.stepId]: {
      ...prevFormData[activeTab.stepId],
      [itemKey]: singleImage.path,
    },
  }));

};
const handleImageDownload = (fileUrl, index, fileType) => {
  let fileExtension = '';
  if (fileType === 'image') {
    fileExtension = 'jpg';
  } else if (fileType === 'audio') {
    fileExtension = 'mp3';
  } else if (fileType === 'video') {
    fileExtension = 'mp4';
  } else if (fileType === 'text') {
    fileExtension = 'txt';
  }
  try {
    saveAs(fileUrl, `${fileType}-${index}.${fileExtension}`);
    toast.success('Download completed.', {autoClose : 2000});
  } catch (error) {
    console.error('File download failed:', error);
    toast.error('Download failed. Please try again.');
  }
};

const getFileName = (filePath) => {
  const path = filePath.split('/');
  const fileName = path[path.length - 1];
  return fileName
}

const handleWebcam = (value, item, flag = false) => {
  const itemKey = item?.key;
  if(!flag) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [activeTab.stepId]: {
        ...prevFormData[activeTab.stepId],
        [itemKey]: value,
      },
    }));
  }
  else {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [activeTab.stepId]: {
        ...prevFormData[activeTab.stepId],
        [itemKey]: null,
      },
    }));
  }
}

  const renderComponent = () => {
    return activeTab.stepName === projectSteps.stepName && projectSteps?.params?.map((item, index) => {
      let component = null;
      FileRef[item.key] = ref;
      const samples = item.samples || [];
      
      switch (item.type) {
        case "uploadfile":
          component = (
            <div className="col-12" key={item.key}>
              <h4>
                {item.label}
                {item.optional === "false" && " * "}
                <span className="required">
                  {getErrorMsg(item.key, item.type )}
                </span>
              </h4>
              <div
                className="upload_box row"
                draggable="true"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                {
                item.key.includes("image") ? (
                  <div className="file_container">
                    <img 
                    className="image"
                      src={
                        formData && typeof formData[activeTab.stepId]?.[item.key] === "object"
                          ? URL?.createObjectURL(
                              formData[activeTab.stepId]?.[item.key][0]
                            )
                          : uploadImg
                      }
                      alt="file preview"
                    />
                  <div className="d_btn row t_center">
                    
                    <input
                    accept={fileAccepts(item.key)}
                    type="file"
                    onChange={(e) => {
                      handleInputChange(
                        activeTab.stepId,
                        item.key,
                        e.target.files
                      );
                    }}
                  />
                </div>
                  </div>
                ) : (
                  <div>
                    <div className="d_btn row t_center">
                  <input
                    accept={fileAccepts(item.key)}
                    type="file"
                    onChange={(e) => {
                      handleInputChange(
                        activeTab.stepId,
                        item.key,
                        e.target.files
                      );
                    }}
                  />
                </div>
                  </div>
                )}
              </div>
              <div>
              {
                item.samples && item.samples.length >= 1 && item.samples[0].name !=="" &&  (
                <div>
                  <div style={{ margin: '20px' }}>
                  <h5 className="samples">
                  <span>Or Choose Form</span>
                </h5>
                </div>
                <div className="sample_image_container">
                    {item.samples.map((image, index) => (
                      <div onClick={ () => handleSampleImage(image, item.type, item.key)}
                      style={{ border : formData?.[activeTab.stepId]?.[item.key] === image.path ? '2px solid #009ADC' : ""}}
                      >{
                        image.type === "image" ? (<img  className="sample_image" key={index} src={image.path} alt="no image" /> ) : (image.type === "text") ? <p>{getFileName(image.path)}</p> : ''
                      }
                      
                      </div>
                    ))} 
                  </div>
                </div>
                )
              }
              </div>
            </div>
          );
          break;
        case "selectbox":
          component = (
            <div key={item.key} className="col-12">
              <h4>
                {item.label}
                {item.optional && " * "}
                <span className="required">
                  {" " + getErrorMsg(item.key)}
                </span>
              </h4>
              <div className="radio_tab">
                <ul>
                  {item.options.map((option, index) => (
                    <li key={index}>
                      <input
                        type="checkbox"
                        name={index}
                        onChange={() =>
                          handleInputChange(
                            activeTab.stepId,
                            item.key,
                            option.value,
                            "select"
                          )
                        }
                        checked={
                          formData[activeTab.stepId]?.[item.key]?.includes(option.value) ?? false
                        }
                      />
                      <label htmlFor={option.name}>{option.label}</label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          );
          break;
        case "text box":
          component = (
            <div className="col-12 wow fadeInUp textcontainer" key={item.key}>
              <div className="img_url_box">
                <div className="form_group">
                  <label Htmlfor="" className="fs-14">
                    {item.label}
                    {item.optional && " * "}
                    <span className="required">
                      {" " + getErrorMsg(item.key)}
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form_control"
                    placeholder="Please Enter image URL"
                    value={formData[activeTab.stepId]?.[item.key]}
                    onChange={(e) =>
                      handleInputChange(
                        activeTab.stepId,
                        item.key,
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </div>
          );
          break;
        case "textarea":
          component = (
            <div className="col-12 wow fadeInUp textcontainer" key={item.key}>
              <div className="img_url_box">
                <div className="form_group">
                  <label htmlfor="" className="fs-14">
                    {item.label}
                    {item.optional && " * "}
                    <span className="required">
                      {" " + getErrorMsg(item.key)}
                    </span>
                  </label>
                  <textarea
                    rows="4"
                    cols="50"
                    className="form_control"
                    placeholder={item.label}
                    value={formData[activeTab.stepId]?.[item.key]}
                    onChange={(e) =>
                      handleInputChange(
                        activeTab.stepId,
                        item.key,
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </div>
          );
          break;
        case "radio":
          component = (
            <div key={item.key} className="col-12">
              <h4>
                {item.label}
                {item.optional && " * "}
                <span className="required">
                  {" " + getErrorMsg(item.key)}
                </span>
              </h4>
              <div className="radio_tab">
                <ul>
                  {item.options.map((option, index) => (
                    <li key={index}>
                      <input
                        type="radio"
                        name={item.key}
                        onChange={() => {}}
                        checked={
                        formData &&  formData[activeTab.stepId]?.[item.key] === option.value ? true : false
                        }
                        
                      />
                      <label
                        onClick={() => {
                          handleInputChange(
                            activeTab.stepId,
                            item.key,
                            option.value
                          );
                        }}
                        htmlFor={option.name}
                      >
                        {option.label}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          );
          break;
        case "webcamVideo" : 
          component = (
            <div className="col-12">
                <WebcamVideo getErrorMsg = {getErrorMsg} item = {item} handleWebcam = {handleWebcam}/>
            </div>
          );
          break;
        case "webcamImage" : 
          component = (
            <div className="col-12">
              <WebcamImage getErrorMsg = {getErrorMsg} item = {item} handleWebcam = {handleWebcam} />
            </div>
          );
          break;
        case "microphone" : 
          component = (
            <div className="col-12">
              <AudioRecorder getErrorMsg = {getErrorMsg} item = {item} handleRecord = {handleWebcam} />
            </div>
          );
          break;
          default:
          break;
      }
      return component;
    });
  };

  return (
    <div className="project_demo">
      <div className="demo_tab row">
        <ToastContainer autoClose={2000} />
        <div className="container">
          <div className="tab_heading">
            <ul className="wow slideInLeft">
              {!loading &&
                projectDetails?.steps?.map((item, index) => {
                  return projectDetails?.steps !== undefined &&
                    projectDetails?.steps?.length === 1 ? (
                    ""
                  ) : (
                    <li
                      key={item.stepId}
                      id={index}
                      className={
                        activeTab.stepId === item.stepId
                          ? "active demo_selection"
                          : "demo_selection"
                      }
                      onClick={() => {
                        handleTabChange(item.stepName, item.stepId);
                      }}
                    >
                      {item.stepName}
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="tab_content row">
            <div className="col-7">
              <div className="tab list col-12" id="one">
                {activeTab.stepName === projectSteps.stepName &&
                  renderComponent()}
                {projectSteps.params && projectSteps.params.length > 0 && (
                  <button className="col-2  form_btn" onClick={handleSubmit}>
                    {projectSteps.stepSendText}
                  </button>
                )}
              </div>
            </div>
            <div className="col-5">
                {demoResponse.Success ? <h1>Output:</h1> : ""}
                {demoResponse.Success === true ? (
                  <div className="response">
                    <div className="item">
                      {demoResponse?.data?.popup?.map((item, index) => {
                        if (item?.type === "image") {
                          return (
                            <div key={index}>
                              <BsFillArrowDownCircleFill  onClick={() =>handleImageDownload(item?.src, index, 'image')} className="download-icon"/>
                              <a href={item?.src} target="_blank">
                              <img
                                className="responseImg"
                                key={index}
                                src={item?.src}
                                alt={`image-${index}`}
                              />
                              </a>
                             </div>
                          );
                        } else if (item?.type === "text" && item?.src) {
                          const textContent = fileContent[index];
                          return (
                            <>
                            <div>
                            <BsFillArrowDownCircleFill  onClick={() =>handleImageDownload(item?.src, index, 'text')} className="download-icon-text"/>
                            </div>
                            <div key={index}  className="responseText"> 
                             {" "}
                              <pre>{textContent}</pre>{" "}
                            </div>
                            </>
                          );
                        } else if (item?.type === "audio") {
                          return (
                            <div key={index}  className="responseImg">
                              onClick={() =>handleImageDownload(item?.src, index, 'audio')}
                              <audio controls>
                                <source src={item?.src} type="audio/mpeg" />
                              </audio>
                            </div>
                          );
                        } else if (item?.type === "video") {
                          return (
                            <div key={index}  className="responseImg">
                              <BsFillArrowDownCircleFill  onClick={() =>handleImageDownload(item?.src, index, 'video')} className="download-icon"/>
                              <video controls width="400" autoPlay={false}>
                                <source src={item?.src} type="video/mp4" />
                              </video>
                            </div>
                          );
                        }
                        return null;
                      })}
                      <div className="item">
                        <div className="item_container">
                        <h1 style={{ margin: "10px" }}>JSON Output:</h1>
                        <BiSolidCopyAlt onClick={copyToClipboard} className="copy-icon" />
                        </div>
                        {isCopied && <p  className="">Copied to clipboard!</p>}
                        <div className="jsonText">
                          <pre>
                            {JSON.stringify(demoResponse.data.result, null, 2)}
                          </pre>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <h1 className="errorMessage">{demoResponse?.data}</h1>
                  </>
                )}
          </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ProjectDemo;

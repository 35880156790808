import React, { useEffect } from "react";
import "../../scss/helpers/_media.scss";
import "../../scss/helpers/_global.scss";
import "../../scss/style.scss";
import Nav from "../../Components/NavBar/Nav";
import CommonBanner from "../../Components/CommonBanner/CommonBanner";
import Footer from "../../Components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getPublishedProjects } from "../../slices/projects";
import ProjectsList from "../../Components/ProjectContainer/ProjectsList";

const ProjectPage = () => {
  const { projectList } = useSelector((state) => state.projects);
  const { loading } = useSelector((state) => state.global);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPublishedProjects());
  }, [dispatch]);

  return (
    <div>
      <Nav />
      {/* <CommonBanner
      // componentdirection1="Home"
      componentdirection2="AI Solutions"
      /> */}
      <ProjectsList projectList={projectList.features} loading={loading} /> 
      <Footer />
    </div>
  );
};
export default ProjectPage;

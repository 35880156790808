import React, { useState } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import "../styles.scss";
import Logo from "../../../images/adminimages/logo.png";
import AuthService from "../../../services/AuthService";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import logo from '../../../images/logo.png';
import LogoSvg from "../../../images/logo.svg"

const authService = new AuthService();
export const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.global);
  const { userRole } = useSelector((state) => state.global);
  const [logoutModal, setlogoutModal] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/controlpanel");
    }
  }, [isLoggedIn, navigate, dispatch]);

  const handleLogout = async () => {
    try {
      await authService.logout(dispatch);
    } catch (error) {}
  };
  const handleClick = () => {
    setlogoutModal(true);
  };
  const handleCancel = () => {
    setlogoutModal(false);
  };
  return (
    <>
      {logoutModal && (
        <div className="popupoverlay">
          <div className="popupbox confirm">
            <h3>Logout</h3>
            <p className="ctxt">Are you sure want to Logout?</p>
            <div className="act-btn">
              <ul>
                <li>
                  <button className="cancel" onClick={handleCancel}>
                    Cancel
                  </button>
                </li>
                <li>
                  <button className="delete" onClick={handleLogout}>
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      <div className="leftbar">
        <figure>
        <Link className="login" to ="/" target="_blank"><img src={LogoSvg} alt="TFT logo" /></Link>
        </figure>
        <ul>
          <li>
            <NavLink
              to="/controlpanel/dashboard"
              className={({ isActive }) => `nav-link ${isActive && "active"}`}
            >
              Dashboard
            </NavLink>
          </li>
          {userRole === "ML" || userRole === "Admin" ? (
            <li>
              <NavLink
                to="/controlpanel/applications"
                className={({ isActive }) => `nav-link ${isActive && "active"}`}
              >
                Applications
              </NavLink>
            </li>
          ) : (
            <></>
          )}
          {userRole === "Admin" ? (
            <li>
              <NavLink
                to="/controlpanel/users"
                className={({ isActive }) => `nav-link ${isActive && "active"}`}
              >
                Users
              </NavLink>
            </li>
          ) : (
            <></>
          )}
          {userRole === "SALES" || userRole === "Admin" ? (
            <li>
              <NavLink
                to="/controlpanel/enquiries"
                className={({ isActive }) => `nav-link ${isActive && "active"}`}
              >
                Enquires
              </NavLink>
            </li>
          ) : (
            <></>
          )}
          {isLoggedIn ? (
            <>
              <ul>
                <li>
                  <NavLink
                    to="/controlpanel/change-password"
                    className={({ isActive }) =>
                      `nav-link ${isActive && "active"}`
                    }
                  >
                    Change Password
                  </NavLink>
                </li>
                <li>
                  <a className="nav-link logout" onClick={handleClick}>
                    Logout
                  </a>
                </li>
              </ul>{" "}
            </>
          ) : (
            <></>
          )}
        </ul>
      </div>
    </>
  );
};

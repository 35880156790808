import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect,useState } from "react";
import AuthService from "../../services/AuthService";
import "./styles.scss";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import eyeOff from '../../images/adminimages/eye-off.png';
import eyeOn from '../../images/adminimages/visible.png';
import { Link } from "react-router-dom";
import LogoSvg from "../../images/logo.svg"


const authService = new AuthService();
const Admin = () => {
  const { isLoggedIn } = useSelector((state) => state.global);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/controlpanel/dashboard");
    }
  }, [isLoggedIn, navigate, dispatch]);

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("username is required"),
    password: Yup.string().required("password is required"),
  });

  const handleSubmit = async (values) => {
    const username = values.username;
    const password = values.password;
    if (username && password) {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);
      try {
        await authService.login(formData, dispatch);
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
    <div className="loginpage-mainbox">
    <div className="main-login-page">
    <div className="logo-container">
        <Link to="/" target="_blank">
          <img className="logo-image"src={LogoSvg} alt="TFT" />
        </Link>
      </div> 
      <div className="login_container">
      <p>Log In</p>
        <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="form-group">
                      <label>Username</label>
                      <Field
                        type="text"
                        className="form-control"
                        placeholder="Username"
                        name="username"
                      />
                      <ErrorMessage
                        name="username"
                        component="div"
                        className="error_message"
                      />
                    </div>
                    <div className="form-group">
                    <label>Password</label>
                    <span className="eyeicon">
                      <img src={showPassword ? eyeOn : eyeOff} alt="Toggle Password Visibility" onClick={togglePasswordVisibility}/>
                      </span>
                      <Field
                         type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Password"
                        name="password"
                        autoComplete="false"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error_message"
                      />
                    </div>
                    <div className="form-group">
                      <button
                        className="form_btn"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Submitting..." : "Login"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
      </div>
      </div>
      </div>
       
    </>
  );
};

export default Admin;

import React from 'react'
import Nav from '../Components/NavBar/Nav'
import CommonBanner from '../Components/CommonBanner/CommonBanner'
import Footer from '../Components/Footer/Footer'
import ConnectForm from '../Components/Connect/ConnectForm'
import addressimg from '../images/address.png'
import emailimg from '../images/email.png'
import phoneimg from '../images/phone.png'

export default function Contact() {
  return (
    <div>
         <Nav />
        <CommonBanner componentdirection2="Contact Us" />
        <ConnectForm/>
        {/* <div className="row contactaddress"><div className='container'>
          <div className='address'>
        <figure><img src={addressimg} alt="" /></figure>
              <div className="rtxt">
                <h4>Address</h4>
                <p>AIHP Tower, 1st, 249G, Phase IV, Gurugram, Haryana 122015</p>
              </div>
              </div>
              <div className='address'>
        <figure><img src={emailimg} alt="" /></figure>
              <div className="rtxt">
                <h4>Email</h4>
                <p><a href="mailto:info@tftus.com">info@tftus.com</a></p>
              </div>
              </div>
              <div className='address'>
        <figure><img src={phoneimg} alt="" /></figure>
              <div className="rtxt">
                <h4>Phone</h4>
                <p>+91-124-280-7000</p>
              </div>
              </div>
        </div></div> */}
        <Footer />
    </div>
  )
}

import React, { Component } from 'react'
import businessValue from '../../images/businessvalues.svg'
import { Link } from 'react-router-dom'

class BusinessValue extends Component {
    render() {
        return (
            <>
                <div className="businee_value row">
                    <div className="container">
                        <div className="value_left wow fadeInDown">
                            <h3>The AI Movement Driving</h3>
                            <h3>Business Value</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting</p>
                            <ul>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting</li>
                            </ul>
                            <div className="dark_d_btn">
                            <a href="https://tft.ai" rel="noreferrer"> Learn More About Us</a>
                            </div>
                        </div>
                        <figure className="wow slideInRight">
                            <img src={businessValue} alt="businessValue"/>
                        </figure>
                    </div>
                </div>
            </>
        )
    }
}

export default BusinessValue
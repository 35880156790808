import React from 'react';

const ClientTestimonial = ({ message, clientImg, name, title }) => {
  return (
    <div className="item">
      <div className="client_msg">{message}</div>
      <div className="client_details">
        <figure className="wow rollIn">
          <img src={clientImg} alt="" />
        </figure>
        <div className="client_name_details">
          <h6>{name}</h6>
          <span>{title}</span>
        </div>
      </div>
    </div>
  );
};

export default ClientTestimonial;

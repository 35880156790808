import React, { useCallback, useRef, useState, useEffect } from "react";
import Webcam from "react-webcam";
import "./WebCam.scss";

export default function WebcamVideo({ getErrorMsg, item, handleWebcam }) {
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  //Recorded video details
  const [recordedVideoFile, setRecordedVideoFile] = useState(null);
  const [recordedVideoUrl, setRecordedVideoUrl] = useState(null);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, setCapturing]);

  const handleDisplay = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });

      const reader = new FileReader();

      reader.onload = function () {
        // The binary data is available in reader.result
        const binaryVideoData = new Uint8Array(reader.result);
        // Convert the binary data to a Blob
        const videoBlob = new Blob([binaryVideoData], { type: "video/webm" });
        // Create a new File from the Blob with a name and type
        const file = new File([videoBlob], "filename.webm", {
          type: "video/webm",
        });
        // Create a Blob URL from the Blob
        const videoUrl = URL.createObjectURL(videoBlob);
        // Set the File and Blob URL in the state
        setRecordedVideoFile(file);
        setRecordedVideoUrl(videoUrl);
      };
      reader.readAsArrayBuffer(blob);
    }
  }, [recordedChunks]);

  useEffect(() => {
    if(recordedVideoFile) {
      handleWebcam(recordedVideoFile, item)
    }
  }, [recordedVideoFile])

  const handleCancel = () => {
    setRecordedChunks([]);
    setRecordedVideoUrl(null);
    setRecordedVideoFile(null);
    handleWebcam(recordedVideoFile, item, true);
  };

  const videoConstraints = {
    width: 420,
    height: 420,
    facingMode: "user",
  };

  return (
    <>
      <div className="webcamContainer Container">
        <h4>
          {item.label}
          {item.optional === "false" && " * "}
          <span className="required">{" " + getErrorMsg(item.key)}</span>
        </h4>
        <div className="upload_box row">
          <div className="file_container">
            {recordedVideoUrl === null ? (
              <Webcam
                className="video"
                audio={false}
                mirrored={false}
                ref={webcamRef}
                videoConstraints={videoConstraints}
              />
            ) : (
              <video className="video" controls src={recordedVideoUrl} />
            )}
          </div>
          {capturing ? (
            <button  className="webcam-btn" onClick={handleStopCaptureClick}>Stop Capture</button>
          ) : recordedChunks.length === 0 ? (
            <button className="webcam-btn" onClick={handleStartCaptureClick}>Start Capture</button>
          ) : null}
          {recordedChunks.length > 0 && (
            <div>
            <button className="webcam-btn" onClick={handleDisplay}>Show Video</button>
            <button className="webcam-btn" onClick={handleCancel}>Retake</button>
            </div>
            
            
          )}
        </div>
      </div>
    </>
  );
}

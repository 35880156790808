import React from 'react';
import Nav from '../NavBar/Nav';
import { useNavigate} from 'react-router-dom';
import Footer from '../Footer/Footer';


const NotFound = () => {
  const navigate = useNavigate();
  const handleClick = () =>{
    navigate("/");

  }
  return (
    
   <div className='no-data-found'>
      <Nav />
      <div className='error-404 '>
        <h1>Ooops, this page does not exist!</h1>
        <p>This page may have moved or changed address.</p>
        <button className='back-to-home' onClick={handleClick}>
        Go to Home page
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;
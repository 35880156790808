import React, { useEffect, useState, useRef } from "react";
import { Sidebar } from "../Sidebar/Sidebar";
import { getEnquiryList } from "../../../slices/admin";
import { useDispatch, useSelector } from "react-redux";
import closeIcon from "../../../images/adminimages/ionic-md-close.png";
import Pagination from "./Pagination";
import UserlistService from "../../../services/userlistService";

const Enquiries = () => {
  const userService = new UserlistService();
  const { enquiryList } = useSelector((state) => state.admin);
  const { enquiryTotalpage } = useSelector((state) => state.admin);
  const [currentPage, setCurrentPage] = useState(1);
  const [isEnquiryFormVisible, setIsEnquiryFormVisible] = useState(false);
  const [selectedEnquiry, setSelectedEnquiry] = useState(null);
  const listInnerRef = useRef();
  const dispatch = useDispatch();
  const initialRender = useRef(true);
  const [pageSize, setPageSize] = useState(10);

  const { totalEnquiries } = useSelector((state) => state.admin);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    }
    dispatch(getEnquiryList(currentPage));
  }, [currentPage, dispatch, isEnquiryFormVisible]);

  // call API for pagination on scroll
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const scrollPosition = scrollTop + clientHeight;
      const threshold = 0.85 * scrollHeight;
      if (scrollPosition >= threshold && enquiryTotalpage > currentPage) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  };
  const handleRowClick = (item) => {
    setSelectedEnquiry(item);
    setIsEnquiryFormVisible(true);
  };
  const handleOnPageChange = (number) => {
    setCurrentPage(number);
  };

  const statusOptions = [
    { value: "Unread", label: "Unread" },
    { value: "Resolved", label: "Resolved" },
    { value: "Inprogress", label: "Inprogress" },
  ];

  const handelStatusChange = async (e) => {
    setSelectedEnquiry({
      ...selectedEnquiry,
      enquiryStatus: e.target.value,
    });
    const payload = {
      "enquiryId": selectedEnquiry.enquiryId,
      "enquiryStatus": e.target.value,
    };
    await userService.postEnquiryStatus(payload);
  };

  return (
    <div>
      {isEnquiryFormVisible && selectedEnquiry && (
        <div className="popupoverlay">
          <div className="popupbox">
            <h3>
              Enquiry Details
              <img
                src={closeIcon}
                alt="close"
                onClick={() => setIsEnquiryFormVisible(false)}
              />
            </h3>
            <form>
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedEnquiry?.fullName}
                  readOnly
                  disabled
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedEnquiry?.emailaddress}
                  readOnly
                  disabled
                />
              </div>
              <div className="form-group">
                <label>Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedEnquiry?.phonenumber}
                  readOnly
                  disabled
                />
              </div>
              <div className="form-group">
                <label>Message</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedEnquiry?.message}
                  disabled
                  readOnly
                />
              </div>
              <div className="form-group">
                <label>Date & Time</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedEnquiry?.datetime}
                  readOnly
                  disabled
                />
              </div>
              <div className="form-group">
                <label>Source</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedEnquiry?.source}
                  readOnly
                  disabled
                />
              </div>
              <div className="form-group">
                <label>Host IP</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedEnquiry?.hostIP}
                  readOnly
                  disabled
                />
              </div>
              <div className="form-group">
                <label>Browser</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedEnquiry?.browser}
                  readOnly
                  disabled
                />
              </div>
              <div >
                <label className="form-status">Status</label>
                <select
                  value={selectedEnquiry?.enquiryStatus}
                  onChange={handelStatusChange}
                  className="form-control"
                >
                  {statusOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </div>
        </div>
      )}
      <Sidebar />
      <div
        className="mainbox"
        // onScroll={onScroll}
        ref={listInnerRef}
      >
        <h1>Enquiries</h1>
        <table>
          <thead>
            <tr>
              <th>Date & Time</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {enquiryList &&
              enquiryList.map((item, index) => {
                return (
                  <tr key={index} onClick={() => handleRowClick(item)}>
                    <td>{item?.datetime}</td>
                    <td>{item?.fullName}</td>
                    <td>{item?.emailaddress}</td>
                    <td>{item?.phonenumber}</td>
                    <td>{item?.enquiryStatus}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <Pagination
          totalCount={totalEnquiries}
          currentPage={currentPage}
          pageSize={pageSize}
          onPageChange={handleOnPageChange}
        />
      </div>
    </div>
  );
};

export default Enquiries;

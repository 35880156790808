import React, { Component } from 'react'
import Slider from 'react-slick';
import clientImg from '../../images/client_pic.png'
import ClientTestimonial from './ClientTestimonial';

class ClientTestimonials extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const testimonials = [
      {
        message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.",
        clientImg: clientImg,
        name: "Uri Akopian",
        title: "Director of Global Support at AlgoSec"
      },
      {
        message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.",
        clientImg: clientImg,
        name: "Uri Akopian",
        title: "Director of Global Support at AlgoSec"
      },
      {
        message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.",
        clientImg: clientImg,
        name: "Uri Akopian",
        title: "Director of Global Support at AlgoSec"
      },
      {
        message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.",
        clientImg: clientImg,
        name: "Uri Akopian",
        title: "Director of Global Support at AlgoSec"
      },
      {
        message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.",
        clientImg: clientImg,
        name: "Uri Akopian",
        title: "Director of Global Support at AlgoSec"
      },
      {
        message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.",
        clientImg: clientImg,
        name: "Uri Akopian",
        title: "Director of Global Support at AlgoSec"
      },
      // Add more testimonials as needed
    ];

    return (
      <div className="client_testimonial row">
        <div className="container">
          <h4 className="heading_style t_center mB0 pB10">
            Happy Clients <span>Company</span>
          </h4>
          <Slider {...settings} id="client_testimonial">
            {testimonials.map((testimonial, index) => (
              <ClientTestimonial key={index} {...testimonial} />
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}

export default ClientTestimonials
import React, { Component } from 'react'
import CommonBanner from '../Components/CommonBanner/CommonBanner'
import Nav from '../Components/NavBar/Nav'
import AboutSection from '../Components/AboutSection/AboutSection'
import Forms from '../Components/FormContainer/Forms'
import Footer from '../Components/Footer/Footer'
import ProfessionalServices from '../Components/ProfessionalServices'
import ClientTestimonials from '../Components/ClientTestimonials'
import ClientProjects from '../Components/ClientandProject/ClientProjects'
import contactFormImg from "../images/contact_img.png";
import '../scss/helpers/_media.scss'
import '../scss/helpers/_global.scss'
import '../scss/mixins/_about_page.scss'
import '../scss/style.scss'

class About extends Component {
  render() {
    return (
      <div>
        <Nav />
        <CommonBanner heading1="About" heading2="Company" description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since" componentdirection="About" />
        <AboutSection />
        <ProfessionalServices />
        <ClientTestimonials />
        <ClientProjects />
        <div className="contact_form">
      <div className="container">
        <h4 className="heading_style wow fadeInUp">
          Let's Connect for <span>free consultation</span>
        </h4>
        <p className="wow fadeInUp">
          So Let us Connect and Transform your idea Into an awesome Solution. Go
          ahead and fill this form.
        </p>

        <div className="flexDirection">
        <div className="form_section">
        <Forms />
        </div>
        <div className="img_right wow slideInRight">
            <img src={contactFormImg} alt="" />
          </div>
        </div>

      </div>
    </div>
        <Footer />
      </div>
    )
  }
}

export default About
import axios from "axios";
import { toast } from "react-toastify";
import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from "../utils/session";

const axiosClient = axios.create({

  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PROD_BASE_URL
      : process.env.REACT_APP_DEV_BASE_URL,

  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    withCredentials: true,
    credentials: "include",
  },
});

export class AxiosBase {
  instance = axiosClient;

  getAuthInstance = async (isFormData = false) => {
    const accessToken = await this.getAccessToken();
    const headers = {
      "Content-Type": isFormData
        ? "multipart/form-data"
        : "application/json",
        Authorization: `Bearer ${accessToken}`,
        withCredentials: true,
        credentials: "include",
    };
    return axios.create({

      baseURL:
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_PROD_BASE_URL
          : process.env.REACT_APP_DEV_BASE_URL,

      timeout: 15000,
      headers: headers,
    });
  };

  getAccessToken = async () => {
    if (getStorageItem("access_expires_in") > new Date().getTime()) {
      return getStorageItem("access_token");
    } else {
      console.log("token is expired");
      const formData = new FormData();
      formData.append("refresh", getStorageItem("access_token"));
      const res = await this.requests.post("auth-refresh/", formData, true);
      const { access } = await res;
      removeStorageItem("access_token");
      setStorageItem("access_token", access);
      return res.access_token;
    }
  };

  responseBody = (response) => {
    return response.data;
  };

  responseError = (error) => {
    console.error(error);
    if(error.response) {
      toast.error(error.response.data.detail);
      throw error;
    }
    throw error;
  };

  serialize = function (obj, url) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p) && obj[p] !== undefined && obj[p] != null) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.length > 0 ? `${url}?${str.join("&")}` : url;
  };

  requests = {
    get: (url) =>
      this.instance.get(url).then(this.responseBody).catch(this.responseError),
    post: (url, data, isFormData = false) => {
      const headers = {
        "Content-Type": isFormData ? "multipart/form-data" : "application/json",
      };
      const config = {
        headers,
      };
      return this.instance
        .post(url, data, config)
        .then(this.responseBody)
        .catch(this.responseError);
    },
    patch: (url, data, isFormData = false) => {
      const headers = {
        "Content-Type": isFormData ? "multipart/form-data" : "application/json",
      };
      const config = {
        headers,
      };
      return this.instance
        .patch(url, data, config)
        .then(this.responseBody)
        .catch(this.responseError);
    },
    getAuth: async (url) => {
      try {
        const axiosInstance = await this.getAuthInstance();
        const response = await axiosInstance.get(url);
        return this.responseBody(response);
      } catch (error) {
        return this.responseError(error);
      }
    },
    postAuth: async (url, body, isFormData = false) => {
      try {
        const headers = {
          "Content-Type": isFormData
            ? "multipart/form-data"
            : "application/json",
        };
        const config = {
          headers,
        };
        const axiosInstance = await this.getAuthInstance(isFormData);
        const response = await axiosInstance.post(url, body);
        return this.responseBody(response);
      } catch (error) {
        return this.responseError(error);
      }
    },
    patchAuth: async (url, body) => {
      try {
        const axiosInstance = await this.getAuthInstance();
        const response = await axiosInstance.patch(url, body);
        return this.responseBody(response);
      } catch (error) {
        return this.responseError(error);
      }
    },
  };
}

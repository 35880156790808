// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransparentLoaderWrapper {
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
}
.TransparentLoaderWrapper .ProgressWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.TransparentLoaderWrapper .ProgressWrapper .CircularProgress {
  border: 5px solid red;
  width: 64px;
  height: 64px;
  border: 5px solid rgb(134, 134, 134);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  z-index: 999;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Loader/loader.scss"],"names":[],"mappings":"AAAA;EAEE,mBAAA;EACA,uBAAA;EACA,OAAA;EACA,MAAA;AAAF;AACE;EACE,eAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AACJ;AAAI;EACE,qBAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,gCAAA;EACA,kBAAA;EACA,qBAAA;EACA,sBAAA;EACA,sCAAA;EACA,YAAA;AAEN;;AAGA;EACE;IACE,uBAAA;EAAF;EAEA;IACE,yBAAA;EAAF;AACF","sourcesContent":[".TransparentLoaderWrapper {\n  // display: flex;\n  align-items: center;\n  justify-content: center;\n  left: 0;\n  top: 0;\n  .ProgressWrapper {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    .CircularProgress {\n      border: 5px solid red;\n      width: 64px;\n      height: 64px;\n      border: 5px solid rgb(134, 134, 134);\n      border-bottom-color: transparent;\n      border-radius: 50%;\n      display: inline-block;\n      box-sizing: border-box;\n      animation: rotation 1s linear infinite;\n      z-index: 999;\n    }\n  }\n}\n\n@keyframes rotation {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

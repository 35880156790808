import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../store";
import { Sidebar } from "../Sidebar/Sidebar";
import { getAllProjects } from "../../../slices/projects";
import { useNavigate, useParams } from "react-router-dom";
import ApplicationsService from "../../../services/ApplicationsService";
import editIcon from "../../../images/edit.svg";
import deleteIcon from "../../../images/delete.svg";
import logo from "../../../images/awesome-image.svg";
import { toast } from "react-toastify";
import "../../Admin/styles.scss";
import ConfirmBox from "../ConfirmBox/ConfirmBox";
import { useRef } from "react";

const applicationsService = new ApplicationsService();
const Applications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.global);
  const { projectList } = useSelector((state) => state.projects);
  const [isDetelePopup, setisDetelePopup] = useState(false)
  const { featureName, featureId } = useParams();
  const handledeletePopupRef = useRef()
  const deleteFeatureRef = useRef();

  useEffect(() => {
    dispatch(getAllProjects());
    if (!isLoggedIn) {
      navigate("/controlpanel");
    }
  }, [isLoggedIn, navigate, dispatch]);

  const handleDeletePopupData = () => {
    setisDetelePopup(!isDetelePopup);
  }
  const handleDeletPopup = (featureId) => {
    setisDetelePopup(!isDetelePopup);
    deleteFeatureRef.featureId = featureId;
  }
  handledeletePopupRef.handleDeletePopup = handleDeletePopupData


  const handleDelete = async (featureId) => {
    if (featureId) {
      const body = {
        featureId,
      };
      try {
        const response = await applicationsService.deleteApplication(
          body,
          dispatch
        );
        if (response && response.Success) {
          toast.success("Application deleted successfully", {
            autoClose: 2000, 
          });
          dispatch(getAllProjects());
        } else {
          toast.error("Application delete failed", {
            autoClose: 2000, 
          });
        }
        handleDeletePopupData();
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const handleEdit = async (featureName, featureId) => {
    navigate(`/controlpanel/application/${featureName}/${featureId}`);
  };

  return (
    <div>
      <Sidebar />
      <div className="mainbox">
        <div>
          <h1 className="heading">
            All Applications
            <div className="new-application">
              <button
                className="form_btn"
                onClick={() => {
                  navigate("new");
                }}
              >
                NEW APPLICATION
              </button>
            </div>
          </h1>
        </div>
        <div>
          <div className="row tableBox">
            <table>
              <tr className="all-application">
                <th className="name-sn">S.N.</th>
                {/* <th>Image</th> */}
                <th className="name-column">Name</th>
                <th className="publish-column">Publish</th>
                <th className="action-column">Action</th>
                {/* <th>&nbsp;</th>
                <th>&nbsp;</th>  */}
              </tr>
              <tbody>
                {projectList?.features?.length > 0 &&
                  projectList.features.map((item, index) => (
                    <tr key={item.featureId}>
                      <td>{index + 1}</td>
                      {/* <td>
                        <img src={logo} height={"30px"} alt="" />
                      </td> */}
                      <td>{item.featureSlug}</td>
                      <td className="publish-column">
                        <div className="switch">
                          <input
                            type="checkbox"
                            name="is_published"
                            checked={item.is_published}
                            readOnly
                          />
                          <span className="slider round"></span>
                        </div>
                      </td>
                      <td className="action-button">
                        <div
                          className="btn"
                          onClick={() => {
                            handleEdit(item.featureName, item.featureId);
                          }}
                        >
                          <em>
                            <img src={editIcon} alt="" />
                          </em>
                        </div>
                        <div
                          className="btn"
                          onClick={() => {
                            handleDeletPopup(item.featureId);
                          }}
                        >
                          <em>
                            <img src={deleteIcon} alt="" />
                          </em>{" "}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isDetelePopup ? (
        <ConfirmBox
          handleDeleteData={handleDelete}
          handleDeletePopup={handledeletePopupRef.handleDeletePopup}
          featureId={deleteFeatureRef.featureId}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Applications;

import React, { Component } from 'react'

export default class Services extends Component {
    render() {
        return (
            <div>
                <div className="col-4">
                    <figure><img src={this.props.serviceImg} alt="" /></figure>
                    <h5>{this.props.serviceName}</h5>
                    <p>{this.props.serviceDescription}</p>
                </div>
            </div>
        )
    }
}

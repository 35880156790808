import React, { Component } from 'react'

export default class ConnectionStep extends Component {
  render() {
    return (
      <div>
        <li className="wow slideInRight"><span>{this.props.stepNumber}</span>
          <div className="right_content">
            <h5 className="fs-18">{this.props.stepName}</h5>
            <p className="fs-16">{this.props.stepDescription}</p>
          </div>
        </li>
      </div>
    )
  }
}

import { toast } from 'react-toastify';
import { setLoading, setLoggedIn } from '../slices/global';
import { AxiosBase } from './AxiosBase';

export default class ApplicationsService extends AxiosBase {
  createApplication = async (formData, dispatch) => {
    try {
      dispatch(setLoading(true))
      const response = await this.requests.postAuth(`/dashboard/addfeature/`, formData, true);
      dispatch(setLoading(false))
      return response;
    } catch (err) {
      console.error(err); 
      dispatch(setLoading(false))
      if(err.message === "Network Error") {
        toast.error("Given token not valid for any token type");
        dispatch(setLoggedIn(false))
      }
      if(!err.response.data.Success) {
        toast.error(err.response.data.data.result)
      }
      throw err.data;
    } finally {
    }
  }
  deleteApplication = async (data, dispatch) => {
    try {
      dispatch(setLoading(true))
      const response = await this.requests.postAuth(`/dashboard/deletefeature/`, data);
      dispatch(setLoading(false))
      return response;
    } catch (err) {
      console.error(err);
      dispatch(setLoading(false))
      if(err.message === "Network Error") {
        toast.error("Given token not valid for any token type");
        dispatch(setLoggedIn(false))
      }
      if(err.response.status === 401) {
        dispatch(setLoggedIn(false))
      }
      throw err;
    }
  }
  updateApplication = async (formData, dispatch) => {
    try {
      dispatch(setLoading(true))
      const response = await this.requests.postAuth(`/dashboard/updatefeature/`, formData, true);
      dispatch(setLoading(false))
      return response;
    } catch (err) {
      console.error(err);
      dispatch(setLoading(false))
      if(err.message === "Network Error") {
        toast.error("Given token not valid for any token type");
        dispatch(setLoggedIn(false))
      }
      return err.data;
    } finally {
    }
  }
  updateStep = async (formData, dispatch) => {
    try {
      dispatch(setLoading(true))
      const response = await this.requests.postAuth(`/dashboard/updatestep/`, formData, true);
      dispatch(setLoading(false))
      return response;
    } catch (err) {
      console.error(err);
      dispatch(setLoading(false))
      if(err.message === "Network Error") {
        toast.error("Given token not valid for any token type");
        dispatch(setLoggedIn(false))
      }
      return err.data;
    } finally {
    }
  }
  addStep = async (formData, dispatch) => {
    try {
      dispatch(setLoading(true))
      const response = await this.requests.postAuth(`/dashboard/addstep/`, formData, true);
      dispatch(setLoading(false))
      return response;
    } catch (err) {
      console.error(err);
      dispatch(setLoading(false))
      if(err.message === "Network Error") {
        toast.error("Given token not valid for any token type");
        dispatch(setLoggedIn(false))
      }
      return err.data;
    } finally {
    }
  }

  deleteSteps = async (payload, dispatch) => {
    try {
      const response = await this.requests.postAuth('/dashboard/deletestep/', payload);
      return response
    }
    catch (err) {
      if(err.message === "Network Error") {
        toast.error("Given token not valid for any token type");
        dispatch(setLoggedIn(false))
      }
      if(err.response.status === 401) {
        dispatch(setLoggedIn(false))
      }
      return;
    }
  }

  uploadFiles = async (payload, dispatch) => {
    try {
      dispatch(setLoading(true))
      const response = await this.requests.postAuth('/dashboard/uploadfiles/', payload, true);
      dispatch(setLoading(false))
      return response;
    }
    catch(e) {
      console.error(e);
      dispatch(setLoading(false))
      return e.data;
    }
  }
}

import { combineReducers } from '@reduxjs/toolkit';

import { reducer as projectsReducer } from '../slices/projects';
import { reducer as adminReducer } from '../slices/admin';
import { reducer as globalReducer } from '../slices/global';

export const rootReducer = combineReducers({
  global: globalReducer,
  projects: projectsReducer,
  admin: adminReducer
});
